import { DAPI_HOST } from '../../config';

export const getUserProfileUrl = userProfileId => `${DAPI_HOST}/v1/user-profiles/${userProfileId}`;

export const getAccountSummaryUrl = accountId => `${DAPI_HOST}/v1/accounts/${accountId}/summary`;

export const getBookingDetails = bookingId => `${DAPI_HOST}/v1/bookings/${bookingId}/details`;

export const getPublicUserProfilesByPhoneUrl = phone =>
  `${DAPI_HOST}/v1/public-user-profiles?phone=${encodeURIComponent(
    phone
  )}&is_hidden=False&is_verified_only=True`;
