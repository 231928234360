export enum FeatureName {
  CALL_CENTER = 'cc',
  CAPACITY = 'c',
  CHANGE_LOCATION_DROPDOWN = 'cld',
  CHAT = 'ch',
  DISABLE_RESERVATIONS = 'dr',
  EMERGENT = 'e',
  FACESHEET_MODAL_PAPERWORK_RESPONSES_TAB = 'fmprt',
  FACESHEET_MODAL_BOOKING_DETAILS_TAB = 'fmbdt',
  FACESHEET_MODAL_BOOKING_HISTORY_TAB = 'fmbht',
  FACESHEET_MODAL_CONSENT_FORMS_TAB = 'fmcft',
  FACESHEET_MODAL_LAB_RESULTS_TAB = 'fmlrt',
  FACESHEET_MODAL_PAYMENTS_TAB = 'fmpayt',
  HANDOFF_PAPERWORK = 'hp',
  HANDOFF_VIDEO_VISIT = 'hvv',
  HELLO_REPORTS_BUTTON = 'hrb',
  HELLO_LOCATIONS_BUTTON = 'hlb',
  HELLO_USERS_BUTTON = 'hub',
  HELLO_SETTINGS_BUTTON = 'hsb',
  HELLO_PAYMENTS_BUTTON = 'hpayb',
  LOCATION_SETTINGS_INTEGRATIONS = 'lsi',
  LOCATION_SETTINGS_LAB_RESULTS = 'lslr',
  LOCATION_SETTINGS_CONSENT = 'lsc',
  LOCATION_SETTINGS_SINGLE_USE_LINKS = 'lssul',
  LOCATION_SETTINGS_GENERAL_INFORMATION = 'lsgi',
  LOCATION_SETTINGS_HOURS_RESERVATION_CAPACITY = 'lshrc',
  LOCATION_SETTINGS_HOURS_AND_SCHEDULING = 'lshs',
  LOCATION_SETTINGS_INSURANCE = 'lsins',
  LOCATION_SETTINGS_INTERNAL_ADMIN = 'lsia',
  LOCATION_SETTINGS_PAPERWORK = 'lsp',
  LOCATION_SETTINGS_SERVICES = 'lss',
  NEXTCARE_MISSING_LOCATION_TEXT = 'nmlt',
  PAPERWORK_RACE_AND_ETHNICITY_FIELDS = 'praef',
  PATIENTS_TAB = 'pt',
  PATIENTS_ACCOUNTS_TAB = 'pat',
  PAYMENTS_TAB = 'payt',
  PATIENTS_LAB_RESULTS = 'palr',
  REVIEWS_MODERATION = 'revsm',
  PAYMENTS_SETTINGS_TAB = 'pst',
  PAYMENTS_SMS_NON_SOLV_PATIENTS = 'psnsp',
  PLATFORM_USAGE_ALERTS = 'pua',
  QUEUE_TAB = 'qt',
  REPORTS_TAB = 'rt',
  REPORTS_PATIENT_BOOST_TAB = 'rpbt',
  REPORTS_PATIENT_EXPERIENCE_TAB = 'rpet',
  REPORTS_REVIEWS_TAB = 'rrt',
  REPORTS_LIVE_STATS_TAB = 'rls',
  REPORTS_PAPERLESS_TAB = 'rpt',
  REPORTS_TELEMED_TAB = 'rtt',
  REPORTS_DOWNLOAD_TAB = 'rdt',
  RESERVATION_HOURS_TAB = 'rht',
  SETTINGS_TAB = 'st',
  SETTINGS_TAB_PROVIDERS_TAB = 'stpt',
  SETTINGS_TAB_PRACTICES_TAB = 'stprt',
  SETTINGS_TAB_USERS_TAB_SERVER_SIDE = 'stutss',
  SETTINGS_TAB_CUSTOM_QUESTIONS_TAB = 'stcqt',
  SETTINGS_TAB_LOCATIONS_TAB = 'stlt',
  SETTINGS_TAB_GROUPS_TAB = 'stgt',
  TELEMED_FLAG = 'tf',
  TRIAGED = 't',
  PATIENT_SEX = 'psx',
  CLINIC_ACCOUNT_GROUPS_EDITOR = 'cage',
  SETTINGS_PATIENT_COMMUNICATIONS = 'spc',
  HELP_CENTER = 'hc',
  FACESHEET_MODAL_VISIT_SUMMARY_TAB = 'fmvst',
  CUSTOM_QUICK_REPLIES = 'cqr',
}
