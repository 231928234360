import ReactGA from 'react-ga';
import { PM_PEDIATRICS_GROUP_ID, TOTAL_ACCESS_URGENT_CARE_GROUP_ID } from '../../config/groups';
import {
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_NO_SHOW,
  BOOKING_STATUS_REJECTED,
  BookingStatus,
} from '../../constants';
import { isEmptyString } from '../util/string';
import { isDev } from '../../config/';
import { isInGroups } from '../util/location';
import { Location, LocationUnformatted } from '../../types/RootState';
import { GenericObject } from '../util/generics';
import { isLocationV2, LocationV2 } from '../../types/dapi/Location';

export const GA_EVENT_TYPE_EVENT = 'event';

export const GA_EVENT_CATEGORY_BOOKING = 'booking';
export const GA_EVENT_CATEGORY_WORKFLOW = 'workflow';

export const GA_EVENT_ACTION_SUBMIT_QUEUE_WALK_IN = 'submit-queue-walk-in';
export const GA_EVENT_ACTION_SUBMIT_QUEUE_CALL_AHEAD = 'submit-queue-call-ahead';
export const GA_EVENT_ACTION_SUBMIT_KIOSK_WALK_IN = 'submit-kiosk-walk-in';
export const GA_EVENT_ACTION_CHECK_IN_KIOSK = 'check-in-kiosk';
export const GA_EVENT_ACTION_CHECK_IN_QUEUE = 'check-in-queue';

export const GA_EVENT_ACTION_BOOKING_CREATED = 'booking-created';

const GA_EVENT_ACTION_STATUS_TO_RESERVED = 'status-change-reserved';
const GA_EVENT_ACTION_STATUS_TO_CONFIRMED = 'status-change-confirmed';
const GA_EVENT_ACTION_STATUS_TO_CHECKED_IN = 'status-change-checked_in';
const GA_EVENT_ACTION_STATUS_TO_IN_EXAM_ROOM = 'status-change-in_exam_room';
const GA_EVENT_ACTION_STATUS_TO_DISCHARGED = 'status-change-discharged';
const GA_EVENT_ACTION_STATUS_TO_CANCELLED = 'status-change-cancelled';
const GA_EVENT_ACTION_STATUS_TO_NO_SHOW = 'status-change-no_show';
const GA_EVENT_ACTION_STATUS_TO_REJECTED = 'status-change-rejected';

export const STATUS_TO_EVENT_MAP = new Map<BookingStatus, string>([
  [BookingStatus.Reserved, GA_EVENT_ACTION_STATUS_TO_RESERVED],
  [BookingStatus.Confirmed, GA_EVENT_ACTION_STATUS_TO_CONFIRMED],
  [BookingStatus.CheckedIn, GA_EVENT_ACTION_STATUS_TO_CHECKED_IN],
  [BookingStatus.InExam, GA_EVENT_ACTION_STATUS_TO_IN_EXAM_ROOM],
  [BookingStatus.Discharged, GA_EVENT_ACTION_STATUS_TO_DISCHARGED],
  [BookingStatus.Cancelled, GA_EVENT_ACTION_STATUS_TO_CANCELLED],
  [BookingStatus.NoShow, GA_EVENT_ACTION_STATUS_TO_NO_SHOW],
  [BookingStatus.Rejected, GA_EVENT_ACTION_STATUS_TO_REJECTED],
]);

const CUSTOM_DIMENSION_GROUPS = [PM_PEDIATRICS_GROUP_ID, TOTAL_ACCESS_URGENT_CARE_GROUP_ID];

const CUSTOM_DIMENSION_1 = 'dimension1';

const fireGaTracking = (
  trackerId: string,
  trackerName: string,
  trackingType = GA_EVENT_TYPE_EVENT,
  opts: {
    eventAction?: string;
    eventCategory?: string;
    forceSsl?: boolean;
    referringDomain?: string;
    eventLabel?: string;
    customDimensions?: any;
  } = {}
) => {
  if (trackingType === GA_EVENT_TYPE_EVENT && (!opts.eventAction || !opts.eventCategory)) {
    throw new Error('Need to pass both eventAction and eventCategory for event tracking');
  }

  const gaOptions = {
    allowLinker: true,
    alwaysSendReferrer: true,
    cookieDomain: 'auto',
    name: trackerName,
  };

  ReactGA.initialize(trackerId, { gaOptions });

  if (opts.forceSsl) {
    ReactGA.ga(`${trackerName}.set`, 'forceSSL', true);
  }

  ReactGA.ga(`${trackerName}.require`, 'linker');

  if (opts.referringDomain) {
    ReactGA.ga(`${trackerName}.linker:autoLink`, [opts.referringDomain]);
  }

  ReactGA.ga(
    `${trackerName}.send`,
    trackingType,
    opts.eventCategory,
    opts.eventAction,
    opts.eventLabel,
    { ...opts.customDimensions }
  );
};

const fireGoogleAnalytics = (
  location: (LocationUnformatted & Location) | LocationV2,
  eventAction: string,
  eventType: string,
  eventCategory: string,
  eventLabelOrBooking: string | { id: string }
) => {
  let eventLabel;
  if (typeof eventLabelOrBooking === 'string') {
    eventLabel = eventLabelOrBooking;
  } else {
    eventLabel = eventLabelOrBooking?.id;
  }

  if (isDev()) {
    console.warn(
      'Hello developer,\nPlease refer to this GA Tracking for Partners documentation and help keep it up to date: https://docs.google.com/document/d/1j_nHRZLgJ94DEI-UA8uXO6r7iaCIB0IEQxcOQIxPsBI/edit?usp=sharing\n<3 gusbot1.0'
    );

    console.warn('Google Analytics Tracking cannot be fired in dev. exiting.');
    return;
  }

  const analytics = isLocationV2(location)
    ? location.trackingProperties?.googleAnalytics
    : location.trackingProperties?.google_analytics;

  if (!analytics) {
    console.warn(`Could not fire tracking! 
      Tracking properties for location ${location.id} are not defined`);
    return;
  }

  let properties: LocationV2['trackingProperties']['googleAnalytics'];

  if (isLocationV2(location)) {
    properties = location.trackingProperties.googleAnalytics;
  } else {
    properties = {
      trackerName: location.trackingProperties.google_analytics.tracker_name,
      id: location.trackingProperties.google_analytics.id,
      referringDomain: location.trackingProperties.google_analytics.referring_domain,
      forceSsl: location.trackingProperties.google_analytics.force_ssl ?? false,
    };
  }
  const { id: trackingId, trackerName, referringDomain, forceSsl } = properties;

  if (isEmptyString(trackingId) || isEmptyString(trackerName) || isEmptyString(referringDomain)) {
    console.error(
      `Could not fire tracking! 
       "trackingId" (${trackingId}), "trackerName" (${trackerName}), "referringDomain" (${referringDomain})
       must be defined correctly for location ${location.id}`
    );
    return;
  }

  const options: GenericObject = {
    referringDomain,
    forceSsl,
  };

  if (eventType === GA_EVENT_TYPE_EVENT) {
    if (isEmptyString(eventAction) || isEmptyString(eventCategory)) {
      console.error(`Could not fire tracking! Tracking properties eventAction
       and eventCategory need to be specified for a GA_EVENT`);
      return;
    }

    Object.assign(options, {
      eventAction,
      eventCategory,
      eventLabel,
    });
  }

  if (isInGroups(location, CUSTOM_DIMENSION_GROUPS)) {
    options.customDimensions = { [CUSTOM_DIMENSION_1]: location.id };
  }

  fireGaTracking(trackingId, trackerName, eventType, options);
};

export { fireGoogleAnalytics };
