import { useForceHttps } from '../hooks/useForceHttps';
import { useHeartBeat } from '../hooks/useHeartBeat';
import { useEffect } from 'react';
import { analyticsTrackEvent } from '../core/analytics';
import { MANAGE_LOADED } from '../core/analytics/events';
import { useSentry } from '../hooks/useSentry';
import { useBooleanFlag } from '../core/flags/flags';
import { enableFastApi } from '../config';

export default function Global() {
  useForceHttps();
  useSentry();
  useHeartBeat();

  useEffect(() => {
    analyticsTrackEvent(MANAGE_LOADED, {});
  }, []);

  useBooleanFlag('use-fast-api', (useFastApi) => {
    if (useFastApi) {
      enableFastApi();
    }
  });

  return null;
}
