import {
  BOOKING_STATUS_RESERVED,
  BOOKING_STATUS_CONFIRMED,
  BOOKING_STATUS_CHECKED_IN,
  BOOKING_STATUS_IN_EXAM_ROOM,
  BOOKING_STATUS_DISCHARGED,
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_NO_SHOW,
} from '../../constants';

export const APP_API_FAILURE = 'App - API failure';

export const CHANGE_LANGUAGE = 'Change language';

export const SIGN_IN_API_NEW_BOOKING = 'Sign in - API booking sent';

export const INSURANCE_TOUCH_INSURANCE_TYPE = 'Insurance - Touch Insurance type';
export const INSURANCE_TOUCH_PHOTO_OF_INSURANCE_CARD_FRONT =
  'Insurance - Touch photo of insurance card (front)';
export const INSURANCE_TOUCH_PHOTO_OF_INSURANCE_CARD_BACK =
  'Insurance - Touch photo of insurance card (back)';
export const INSURANCE_TOUCH_ENTER_MANUALLY = 'Insurance - Touch enter manually';
export const INSURANCE_TOUCH_MEMBER_ID = 'Insurance - Touch Member id';
export const INSURANCE_TOUCH_BIRTH_DATE = 'Insurance - Touch Birth Date';
export const INSURANCE_TOUCH_INSURANCE_CARRIER = 'Insurance - Touch Insurance Carrier';
export const INSURANCE_API_ELIGIBILITY_CALL = 'Insurance - API Eligibility call';
export const INSURANCE_TOUCH_NEXT_INSURANCE_COMPLETED =
  'Insurance - Touch Next Insurance completed';
export const INSURANCE_TOUCH_SKIP_FROM_INSURANCE_INFO =
  'Insurance - Touch Skip from Insurance info';
export const INSURANCE_TOUCH_SHOW_EXTENDED_DETAILS = 'Insurance - Show Extended Details';
export const INSURANCE_TOUCH_SHOW_DOWNLOAD_CARD = 'Insurance - Download Card';
export const INSURANCE_TOUCH_SHOW_PRINT_SUMMARY = 'Insurance - Print Summary';
export const INSURANCE_FORM_VALIDATION_ERROR = 'Insurance - Form validation error';

export const REPORT_DATE_SELECTOR = 'Report - Touch Date Selector';

export const INSURANCE_FORM_TOUCH_ELIGIBILITY_RATING = 'Insurance - Touch eligibility rating';
export const INSURANCE_FORM_TOUCH_ELIGIBILITY_RATING_DEATILS =
  'Insurance - Touch eligibility rating details';
export const INSURANCE_ELIGIBILITY_RATING_ERROR = 'Insurance - Eligibility rating error';

export const CHECKIN_SUBMIT = 'checkin/SUBMIT';
export const CHECKIN_GO_BACK = 'checkin/GO_BACK';
export const CHECKIN_NOT_YOU = 'checkin/NOT_YOU';
export const CHECKIN_MAKE_ANOTHER_RESERVATION = 'checkin/MAKE_ANOTHER_RESERVATION';
export const RETURNING_PATIENT_SELECT = 'returning/PATIENT_SELECT';
export const NEW_PATIENT_SUBMIT = 'newPatient/SUBMIT';
export const LOOKUP_VALIDATION_ERROR = 'lookup/VALIDATION_ERROR';

export const TOUCH_NOT_ARRIVED = 'Booking List - Touch Not Arrived';
export const BOOKING_LIST_ACTIVE_ROW = 'BookingList - ActiveRow';

export const TOP_NAV_TOUCH_MENU_ITEM = 'Top Nav - Touch Menu Item';
export const TOP_NAV_CHANGE_QUEUE_LOCATION = 'Top Nav - Change Queue Location';

export const HELLO_CHANGE_QUEUE_LOCATION = 'Hello - Change Queue Location';
export const HELLO_TOUCH_BUTTON = 'Hello - Touch Button';

export const SEND_FOLLOW_UP_APPOINTMENT_TEXT = 'Follow Up Appointment Text - Send';
export const CANCEL_FOLLOW_UP_APPOINTMENT_TEXT = 'Follow Up Appointment Text - Cancel sending';

export const PULSE_PAGE_LOAD = 'pulse/PAGE_LOAD';
export const PULSE_REFRESH_BUTTON_CLICK = 'pulse/REFRESH_BUTTON_ON_CLICK';
export const PULSE_REFRESH_AUTO_REFRESH = 'pulse/REFRESH_AUTO_REFRESH';
export const PULSE_SHOW_ADD_TO_HOME = 'pulse/SHOW_ADD_TO_HOME';
export const PULSE_SHOW_ADD_TO_HOME_TAP_TO_CLOSE = 'pulse/SHOW_ADD_TO_HOME_TAP_TO_CLOSE';

export const REPORTS_PULSE_BUTTON_CLICK = 'reports/PULSE_BUTTON_ON_CLICK';

export const TOKBOX_REQUIREMENTS_NOT_MET = 'Tokbox - Requirements not met';
export const TOKBOX_CLIENT_INFO = 'Tokbox - Manage info';
export const VIDEO_VISIT_MODAL = 'Manage - video visit modal';
export class VideoVisitModalActions {
  static CLICK_SAVE_NOTES = 'click save notes';
  static CLICK_VISIT_COMPLETED = 'click visit completed';
  static CLICK_JOIN_VIDEO = 'click join video';
  static CLICK_VISIT_NOT_COMPLETED = 'click visit not completed';
  static CLICK_LEAVE_VIDEO = 'click leave video';
}

export class PatientsTabBookingsSearchFiltersEvents {
  static email = 'Patients tab - Bookings search filter email selected';
  static first_name = 'Patients tab - Bookings search filter first name selected';
  static last_name = 'Patients tab - Bookings search filter last name selected';
  static phone = 'Patients tab - Bookings search filter phone selected';
}

export const CHAT_BOOKING_ROW_BUTTON_CLICK = 'chat/CHAT_BOOKING_ROW_BUTTON_CLICK';
export const CHAT_ACTION_BUTTON_CLICK = 'chat/CHAT_ACTION_BUTTON_CLICK';
export const CHAT_FREE_TEXT_SEND_CLICK = 'chat/CHAT_FREE_TEXT_SEND_CLICK';
export const CHAT_CANNED_MESSAGE_SEND_CLICK = 'chat/CHAT_CANNED_MESSAGE_SEND_CLICK';
export const CHAT_TELEMED_VIDEO_CLOSE_BUTTON_CLICK = 'chat/CHAT_TELEMED_VIDEO_CLOSE_BUTTON_CLICK';

export const QUEUE_TAP_LOGIN = 'Manage - Tap Log In';

export const HELLO_CLICK_REPORTS = 'Manage - Click Reports';
export const HELLO_CLICK_PULSE = 'Manage - Click Pulse';
export const HELLO_CLICK_USERS = 'Manage - Click Users';
export const HELLO_CLICK_SETTINGS = 'Manage - Click Settings';
export const HELLO_CLICK_PAYMENTS = 'Manage - Click Payments';
export const HELLO_CLICK_PATIENTS = 'Manage - Click Patients';
export const HELLO_CLICK_CALL_CENTER = 'Manage - Click Call Center';
export const HELLO_CLICK_PATIENTS_PER_HOUR_BANNER = 'Manage - Click Patients Per Hour Banner';

export const TOP_NAV_SELECT_QUEUE = 'Manage Header - Click Queue';
export const TOP_NAV_SELECT_REPORTS = 'Manage Header - Click Reports';
export const TOP_NAV_SELECT_USERS = 'Manage Header - Click Users';
export const TOP_NAV_SELECT_SETTINGS = 'Manage Header - Click Settings';
export const TOP_NAV_SELECT_PAYMENTS = 'Manage Header - Click Payments';
export const TOP_NAV_SELECT_REVIEWS_MODERATION = 'Manage Header - Click Reviews Moderation';
export const TOP_NAV_SELECT_LOG_OUT = 'Manage Header - Click Log Out';
export const TOP_NAV_SELECT_CALL_CENTER = 'Manage Header - Click Call Center';
export const TOP_NAV_SELECT_LOCATION = 'Dropdown - Change Selected Clinic';
export const TOP_NAV_SELECT_RESPONSIVE_REPORTS_LIVE_STATS =
  'Manage Header - Click Responsive Reports Pulse';

export const QUEUE_CLICK_RESERVED_TAB = 'Queue - Click Reserved Tab';
export const QUEUE_CLICK_HERE_TAB = 'Queue - Click Here Tab';
export const QUEUE_CLICK_ALL_DONE_TAB = 'Queue - Click All Done Tab';
export const QUEUE_CLICK_ADD_PATIENT_ICON = 'Queue - Click Add Patient Icon';
export const QUEUE_CLICK_ADD_PATIENT_SUBMIT = 'Queue - Click Add Patient Submit';
export const QUEUE_CLICK_HEARTS_ICON = 'Queue - Click Hearts Icon';
export const QUEUE_CLICK_WAITLIST_ICON = 'Queue - Click Waitlist Icon';
export const QUEUE_CLICK_SEARCH_ICON = 'Queue - Click Search Icon';
export const QUEUE_CLICK_ADD_NOTES = 'Queue - Click Add Notes';
export const QUEUE_CLICK_SOLV_PATIENT_BOOST = 'Queue - Click Solv Patient Boost';
export const QUEUE_CLICK_BOOK_FOLLOWUP_ON_COMPLETED_ROW =
  'Queue - Click book followup on completed row';
export const QUEUE_CLICK_BOOK_FOLLOWUP_ON_FACESHEET_MODAL =
  'Queue - Click book followup on facesheet modal';
export const QUEUE_CLICK_BOOK_FOLLOWUP_SUBMIT = 'Queue - Click book followup submit';

export const FACESHEET_MODAL_CLICK_INSURANCE_DOWNLOAD =
  'Queue - Facesheet Modal - Download Insurance';
export const FACESHEET_MODAL_CLICK_TWO_SIDED_INSURANCE_CARD_DOWNLOAD =
  'Queue - Facesheet Modal - Download Two Sided Insurance Card';
export const FACESHEET_MODAL_CLICK_PHOTO_ID_DOWNLOAD =
  'Queue - Facesheet Modal - Download Photo ID';
export const FACESHEET_MODAL_CLICK_PHOTO_ID_PRINT = 'Queue - Facesheet Modal - Print Photo ID';
export const FACESHEET_MODAL_CLICK_INSURANCE_ADDITIONAL_DETAILS =
  'Queue - Facesheet Modal - Insurance Additional Details';
export const FACESHEET_MODAL_CLICK_INSURANCE_SUBMIT =
  'Queue - Facesheet Modal - Click Submit Insurance';
export const FACESHEET_MODAL_CLICK_STATUS_RESERVED =
  'Queue - Facesheet Modal - Click Status Reserved';
export const FACESHEET_MODAL_CLICK_STATUS_HERE = 'Queue - Facesheet Modal - Click Status Here';
export const FACESHEET_MODAL_CLICK_STATUS_READY = 'Queue - Facesheet Modal - Click Status Ready';
export const FACESHEET_MODAL_CLICK_STATUS_IN_EXAM =
  'Queue - Facesheet Modal - Click Status In Exam';
export const FACESHEET_MODAL_CLICK_STATUS_DONE = 'Queue - Facesheet Modal - Click Status Done';
export const FACESHEET_MODAL_CLICK_STATUS_CANCELED =
  'Queue - Facesheet Modal - Click Status Canceled';
export const FACESHEET_MODAL_CLICK_STATUS_NO_SHOW =
  'Queue - Facesheet Modal - Click Status No Show';
export const FACESHEET_MODAL_CLICK_TRANSFER = 'Queue - Facesheet Modal - Click Transfer';
export const FACESHEET_MODAL_EDIT_BUTTON_CLICK = 'Queue - Facesheet Modal - Edit Button Click';
export const FACESHEET_MODAL_COPY_FIRST_NAME_CLICK = 'Queue - Facesheet Modal - Copy First Name';
export const FACESHEET_MODAL_COPY_LAST_NAME_CLICK = 'Queue - Facesheet Modal - Copy Last Name';
export const FACESHEET_MODAL_COPY_DOB_CLICK = 'Queue - Facesheet Modal - Copy DOB';
export const FACESHEET_MODAL_COPY_PHONE_CLICK = 'Queue - Facesheet Modal - Copy Phone';
export const FACESHEET_MODAL_COPY_EMAIL_CLICK = 'Queue - Facesheet Modal - Copy Email';
export const FACESHEET_MODAL_COPY_REASON_FOR_VISIT =
  'Queue - Facesheet Modal - Copy Reason For Visit';
export const FACESHEET_MODAL_COPY_NOTES = 'Queue - Facesheet Modal - Copy Notes';
export const FACESHEET_MODAL_COPY_PHARMACY = 'Queue - Facesheet Modal - Copy Pharmacy';
export const FACESHEET_MODAL_COPY_PREFERRED_LANGUAGE =
  'Queue - Facesheet Modal - Copy Preferred Language';
export const FACESHEET_PAPERWORK_TAB_CLICK_SAVE = 'Facesheet - Paperwork Tab - Click Save';

export const REPORTS_SELECT_PATIENT_BOOST = 'Manage - Reports - Select Patient Boost';
export const REPORTS_SELECT_PATIENT_EXPERIENCE = 'Manage - Reports - Select Patient Experience';
export const REPORTS_SELECT_REVIEWS = 'Manage - Reports - Select Reviews';
export const REPORTS_SELECT_PAPERLESS = 'Manage - Reports - Select Paperless';
export const REPORTS_SELECT_TELEMED = 'Manage - Reports - Select Telemed';
export const REPORTS_SELECT_DOWNLOAD_REPORT = 'Manage - Reports - Select Download Report';
export const REPORTS_SELECT_LIVE_STATS = 'Manage - Reports - Select Live Stats';
export const REPORTS_SELECT_DATE_RANGE = 'Manage - Reports - Select Date Range';
export const REPORTS_REVIEWS_SELECT_TYPE = 'Manage - Reports - Reviews - Select Type';
export const REPORTS_REVIEWS_SELECT_STARS = 'Manage - Reports - Reviews - Select Stars';
export const REPORTS_REVIEWS_SELECT_CONTACTED = 'Manage - Reports - Reviews - Select Contacted';
export const REPORTS_REVIEWS_SELECT_FILTER_OPTION =
  'Manage - Reports - Reviews - Select Filter Option';
export const REPORTS_REVIEWS_CHECK_CONTACTED = 'Manage - Reports - Reviews - Check Contacted';
export const REPORTS_DOWNLOAD_PATIENT_REPORT = 'Manage - Reports - Download - Patient Report';
export const REPORTS_DOWNLOAD_FUTURE_PATIENT_REPORT =
  'Manage - Reports - Download - Future Patient Report';
export const REPORTS_DOWNLOAD_AGGREGATE_PATIENT_REPORT =
  'Manage - Reports - Download - Aggregate Patient Report';
export const REPORTS_DOWNLOAD_CLINIC_REPORT = 'Manage - Reports - Download - Clinic Report';
export const REPORTS_PATIENT_BOOST = 'Manage - Reports - Patient Boost';
export const REPORTS_PATIENT_BOOST_SOLV_REVENUE_CHANGED =
  'Reports - Patient Boost - Solv revenue changed';
export const REPORTS_PATIENT_EXPERIENCE_LOAD = 'Reports - Patient Experience - Page Load';
export const REPORTS_PATIENT_BOOST_LOAD = 'Reports - Patient Boost - Page Load';
export const REPORTS_PAPERLESS_LOAD = 'Reports - Paperless - Page Load';
export const REPORTS_TELEMED_LOAD = 'Reports - Telemed - Page Load';
export const REPORTS_PATIENT_EXPERIENCE_TOGGLE_REVIEWS =
  'Reports - Patient Experience - Toggle Reviews';
export const REPORTS_INFO_HOVER = 'Reports - Info Hover';
export const REPORTS_PERIOD_CHANGED = 'Reports - Period changed';

export const REVIEWS_MODERATION_SELECT_MODERATE = 'Manage - Reviews Moderation - Select Moderate';
export const REVIEWS_MODERATION_SELECT_VIEW = 'Manage - Reviews Moderation - Select View';

export const PAYMENTS_CLICK_SEARCH = 'Manage - Payments - Click Search';
export const PAYMENTS_ACTIONS_VIEW_BILL = 'Manage - Payments - Actions - View Bill';
export const PAYMENTS_ACTIONS_SEND_BILL = 'Manage - Payments - Actions - Send Bill';
export const PAYMENTS_CLICK_UPLOAD_BILL = 'Manage - Payments - Click Upload Bill';
export const PAYMENTS_CLICK_CREATE_PATIENT =
  'Manage - Payments - Click Create Patient and Send SMS';
export const PAYMENTS_CLICK_RESEND_SMS_BILL = 'Manage - Payments - Click Resend SMS Bill';

export const KIOSK_CLICK_GET_STARTED = 'Kiosk - Click - Get Started';
export const KIOSK_TOUCH_MOBILE_PHONE = 'Kiosk - Touch - Mobile phone';
export const KIOSK_CLICK_TOS_BOX = 'Kiosk - Click - TOS box';
export const KIOSK_CLICK_BEGIN = 'Kiosk - Click - Begin';
export const KIOSK_CLICK_LOOKUP_PHONE = 'Kiosk - Click - Lookup - Phone';
export const KIOSK_CLICK_CONFIRM_PHONE = 'Kiosk - Click - Confirm - Phone';
export const KIOSK_CLICK_BOOK_AHEAD_NO = 'Kiosk - Click - Book Ahead - No';
export const KIOSK_CLICK_BOOK_AHEAD_YES = 'Kiosk - Click - Book Ahead - Yes';
export const KIOSK_CLICK_START_OVER_NEW_PATIENT = 'Kiosk - Click - Start over - New Patient';
export const KIOSK_CLICK_MORE_TIME_YES = 'Kiosk - Click - More time - Yes';
export const KIOSK_CLICK_MORE_TIME_NO = 'Kiosk - Click - More time - No';
export const KIOSK_CLICK_ADD_SOMEONE_NEW = 'Kiosk - Click - Add Someone New';
export const KIOSK_CLICK_START_OVER_CHECK_IN = 'Kiosk - Click - Start Over - Check in';
export const KIOSK_CLICK_START_OVER_RETURNING_PATIENT =
  'Kiosk - Click - Start over - Returning Patient';
export const KIOSK_TOGGLE_PROFILE = 'Kiosk - Toggle - Profile';

export const LOGIN_RESET_PASSWORD_SUCCESS = 'Login - Reset Password Email Correct';
export const LOGIN_RESET_PASSWORD_FAILURE = 'Login - Reset Password Email Incorrect';

export const TRIGGER_TO_EVENT_NAME_MAP = new Map([
  [BOOKING_STATUS_RESERVED, FACESHEET_MODAL_CLICK_STATUS_RESERVED],
  [BOOKING_STATUS_CONFIRMED, FACESHEET_MODAL_CLICK_STATUS_HERE],
  [BOOKING_STATUS_CHECKED_IN, FACESHEET_MODAL_CLICK_STATUS_READY],
  [BOOKING_STATUS_IN_EXAM_ROOM, FACESHEET_MODAL_CLICK_STATUS_READY],
  [BOOKING_STATUS_DISCHARGED, FACESHEET_MODAL_CLICK_STATUS_DONE],
  [BOOKING_STATUS_CANCELLED, FACESHEET_MODAL_CLICK_STATUS_CANCELED],
  [BOOKING_STATUS_NO_SHOW, FACESHEET_MODAL_CLICK_STATUS_NO_SHOW],
  ['Edit', FACESHEET_MODAL_EDIT_BUTTON_CLICK],
  ['Transfer', FACESHEET_MODAL_CLICK_TRANSFER],
  ['Copy First Name', FACESHEET_MODAL_COPY_FIRST_NAME_CLICK],
  ['Copy Last Name', FACESHEET_MODAL_COPY_LAST_NAME_CLICK],
  ['Copy Date of Birth', FACESHEET_MODAL_COPY_DOB_CLICK],
  ['Copy Phone Number', FACESHEET_MODAL_COPY_PHONE_CLICK],
  ['Copy Email Address', FACESHEET_MODAL_COPY_EMAIL_CLICK],
  ['Copy Reason for Visit', FACESHEET_MODAL_COPY_REASON_FOR_VISIT],
  ['Copy Notes', FACESHEET_MODAL_COPY_NOTES],
  ['Copy Preferred Language', FACESHEET_MODAL_COPY_PREFERRED_LANGUAGE],
  ['Copy Preferred Pharmacy', FACESHEET_MODAL_COPY_PHARMACY],
]);

export const SETTINGS_PAGE_CATEGORY = 'Manage - Settings';
export const SETTINGS_GENERAL_INFORMATION_PAGE_LOAD = 'General Information';
export const SETTINGS_HOURS_AVAILABILITY_PAGE_LOAD = 'Hours and Availability';
export const SETTINGS_SERVICES_PAGE_LOAD = 'Services';
export const SETTINGS_INSURANCE_PAGE_LOAD = 'Insurance';
export const SETTINGS_CLICK_SAVE = 'Manage - Settings - Click Save';
export const SETTINGS_CLICK_INVALIDATE_CACHE = 'Settings - Click Invalidate Cache';

export const SETTINGS_CLICK_GENERATE_SINGLE_USE_LINKS =
  'Settings - Click Generate Single Use Links';

export const WEB_PUSH_OPT_IN = 'Web push opt in';

export class HandoffEvents {
  static CLICK_GENERATE_CODE = 'Handoff - Click Generate Code';
}

export const COVID_TEST_RESULT_TIME_BANNER = 'Covid Testing Result Time Banner';

export class CovidTestResultTimeBannerActions {
  static VIEW_BANNER = 'View Banner';
  static CLOSE_BANNER = 'Close Banner';
}

export class SolvPlusChargeDialog {
  static BASE = 'Solv Plus Charge Dialog';
  static SHOWN = 'Solv Plus Charge Dialog -- Shown';
  static CHARGED = 'Solv Plus Charge Dialog -- Clicked Charge';
  static NOT_CHARGED = 'Solv Plus Charge Dialog -- Clicked No Charge';
}

export class SolvPlusInPersonWrapUp {
  static BASE = 'Solv Plus In-Person Wrap Up Flow';
  static STARTED = 'Started wrap-up';
  static CHARGED = 'Charged';
  static NOT_CHARGED = 'Not charged';
}

export const MANAGE_LOADED = 'Manage Loaded';
export const MANAGE_UPDATE = 'Manage Update';

export const MANAGE_PERFORMANCE_METRICS = 'Manage Performance Metrics';

export const CLEAR_PRICES_PROMPT_MODAL = 'Manage - self-pay price modal';
export enum ClearPricesPromptModalActions {
  MODAL_SEEN = 'Modal visible',
  REVIEW_PRICES_CLICKED = 'Clicked review prices',
  MODAL_DISMISSED = 'Clicked dismiss',
  REMIND_LATER_CLICKED = 'Clicked remind me later',
}

export const MANAGE_LOADED_IN_IFRAME = 'Manage - Loaded in iframe';
