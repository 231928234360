import moment from 'moment-timezone';
import {
  ACCOUNT_ROLE_FRONT_OFFICE,
  ACCOUNT_ROLE_BACK_OFFICE,
  ACCOUNT_ROLE_SOLV_EMPLOYEE,
  ACCOUNT_ROLE_BILLING,
  ACCOUNT_ROLE_MANAGER,
  ACCOUNT_ROLE_PROVIDER,
  ACCOUNT_ROLE_CALL_CENTER,
  ACCOUNT_ROLE_IT_ADMIN,
  ACCOUNT_ROLE_ADMIN,
  PackageName,
  ServiceIdentifiers,
  ACCOUNT_ROLE_CALL_CENTER_MANAGER,
} from '../../constants';
import { SMART_CARE_LOCATION_IDS } from '../../config/clinic';
import {
  isMobileCheckinEnabled,
  isPhysicalLocation,
  isProviderGroupLocation,
} from '../util/location';
import {
  CITYDOC_GROUP_ID,
  NEXTCARE_GROUP_ID,
  NEXTCARE_SOLV_TESTING_GROUP_ID,
  URGENT_CARE_FOR_KIDS_GROUP_ID,
  CHILDRENS_URGENT_CARE_GROUP_ID,
  RENOWN_GROUP_ID,
  RENOWN_TRAINING_GROUP_ID,
  POP_CARE_PLUS_IMMEDIATE_CARE_GROUP_ID,
  PORTLAND_URGENT_CARE_GROUP_ID,
  ALLIANCE_GROUP_ID,
  MULTICARE_GROUP_ID,
  MULTICARE_TRAINING_GROUP_ID,
  WESTMED_GROUP_ID,
  URGENT_TEAM_GROUP_ID,
  BETTERMED_GROUP_ID,
  LITTLE_SPURS_GROUP_ID,
  FORMATION_HEALTH_GROUP_ID,
  MI_EXPRESS_CARE_GROUP_ID,
  CITY_LIFE_HEALTH_GROUP_ID,
  SMART_CARE_GROUP_ID,
  MEDSTAR_GROUP_ID,
  MEDSTAR_TELEMED_GROUP_ID,
} from '../../config/groups';
import { isIntegrationEnabled } from '../util/integrationStatus';
import { AccountLocation, AccountLogin, GroupLocation, Location } from '../../types/RootState';

const isLoggedIn = (login: AccountLogin) => {
  return Boolean(login?.account?.id);
};

const isAdmin = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_ADMIN);
};

const isSolvEmployee = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_SOLV_EMPLOYEE) || isAdmin(login);
};

const isBilling = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_BILLING);
};

const isItAdmin = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_IT_ADMIN);
};

const isManager = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return (
    login.account.roles.includes(ACCOUNT_ROLE_MANAGER) ||
    login.account.roles.includes(ACCOUNT_ROLE_CALL_CENTER_MANAGER)
  );
};

const isNormalManager = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_MANAGER);
};

const isCallCenterManager = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_CALL_CENTER_MANAGER);
};

const isProvider = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_PROVIDER);
};

const isInGroup = (login: AccountLogin, groupId: string) => {
  return !!login?.account?.groups?.some((group) => group.group_id === groupId);
};

const isNextCareSolvTestingUser = (login: AccountLogin) =>
  isInGroup(login, NEXTCARE_SOLV_TESTING_GROUP_ID);

const isNextCareUser = (login: AccountLogin) => isInGroup(login, NEXTCARE_GROUP_ID);

const isMultiCareUser = (login: AccountLogin) =>
  isInGroup(login, MULTICARE_GROUP_ID) || isInGroup(login, MULTICARE_TRAINING_GROUP_ID);

const isBetterMedUser = (login: AccountLogin) => isInGroup(login, BETTERMED_GROUP_ID);

const isAllianceUser = (login: AccountLogin) => isInGroup(login, ALLIANCE_GROUP_ID);

const isMedstarUser = (login: AccountLogin) =>
  isInGroup(login, MEDSTAR_GROUP_ID) || isInGroup(login, MEDSTAR_TELEMED_GROUP_ID);

const isRenownUser = (login: AccountLogin) => isInGroup(login, RENOWN_GROUP_ID);

const isRenownTrainingUser = (login: AccountLogin) => isInGroup(login, RENOWN_TRAINING_GROUP_ID);

const isUrgentCareForKidsUser = (login: AccountLogin) =>
  isInGroup(login, URGENT_CARE_FOR_KIDS_GROUP_ID);

const isChildrensUrgentCareUser = (login: AccountLogin) =>
  isInGroup(login, CHILDRENS_URGENT_CARE_GROUP_ID);

const isLittleSpursUser = (login: AccountLogin) => isInGroup(login, LITTLE_SPURS_GROUP_ID);

const isFormationHealthUser = (login: AccountLogin) => isInGroup(login, FORMATION_HEALTH_GROUP_ID);

const isMiExpressCareUser = (login: AccountLogin) => isInGroup(login, MI_EXPRESS_CARE_GROUP_ID);

const isSmartCareUser = (login: AccountLogin) => isInGroup(login, SMART_CARE_GROUP_ID);

const isCityLifeHealthUser = (login: AccountLogin) => isInGroup(login, CITY_LIFE_HEALTH_GROUP_ID);

const isPopCarePlusImmediateCareUser = (login: AccountLogin) =>
  isInGroup(login, POP_CARE_PLUS_IMMEDIATE_CARE_GROUP_ID);

const isPortlandUrgentCareUser = (login: AccountLogin) =>
  isInGroup(login, PORTLAND_URGENT_CARE_GROUP_ID);

const isCitydocUser = (login: AccountLogin) => isInGroup(login, CITYDOC_GROUP_ID);

const isWestmedUser = (login: AccountLogin) => isInGroup(login, WESTMED_GROUP_ID);

const isUrgentTeamUser = (login: AccountLogin) => isInGroup(login, URGENT_TEAM_GROUP_ID);

const isCallCenterRep = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_CALL_CENTER);
};

const isNextCareCallCenter = (login: AccountLogin) =>
  isNextCareUser(login) && isCallCenterRep(login);

const isLabResultsAccessEnabled = (login: AccountLogin) =>
  !!login?.account?.lab_results_access_enabled;

const getGroupsIds = (login: AccountLogin) => login.account.groups.map((group) => group.group_id);

const getGroupsLocations = (login: AccountLogin, opts: { isSolvPartnerOnly?: boolean } = {}) => {
  let locations: GroupLocation[] = [];
  login.account.groups.forEach((group) => {
    locations = locations.concat(group.locations);
  });

  if (opts.isSolvPartnerOnly) {
    return locations.filter((location) => !!location.is_solv_partner);
  }

  return locations;
};

const requiresPasswordReset = (login: AccountLogin) => {
  return !!login?.account?.requires_password_reset;
};

const expiredPasswordRequiresReset = (login: AccountLogin) => {
  const passwordExpirationDate = login?.account?.password_expiration_date;
  return moment().isAfter(passwordExpirationDate);
};

const isFrontOfficeUser = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_FRONT_OFFICE);
};

const isBackOfficeUser = (login: AccountLogin) => {
  if (!isLoggedIn(login)) {
    return false;
  }

  return login.account.roles.includes(ACCOUNT_ROLE_BACK_OFFICE);
};

const isSolvExpressLocation = (location: Location | AccountLocation) => {
  return location?.package_name === PackageName.SolvExpress;
};

const hasAccessToLocation = (login: AccountLogin, locationId: string) => {
  return !!login?.account?.locations?.some((location) => location.location_id === locationId);
};

const hasAccessToSmartCaresLocations = (login: AccountLogin) =>
  SMART_CARE_LOCATION_IDS.every(
    (locationId) =>
      !!login?.account?.locations?.some((location) => location.location_id === locationId)
  );

const hasAccessToLocationWithPaperwork = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_paperwork_enabled);

const hasAccessToLocationWithKioskCustomQuestions = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_kiosk_custom_questions_enabled);

const hasAccessToLocationWithPayments = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_payments_enabled);

const hasAccessToLocationWithConsent = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_consent_enabled);

const hasAccessToLocationWithPaperlessHandoff = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_paperless_handoff_enabled);

const hasAccessToLocationWithVideoVisitHandoff = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_video_visit_handoff_enabled);

const hasAccessToTelemedLocation = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_telemed);

const hasAccessToDrChronoLocation = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) =>
    isIntegrationEnabled(
      { serviceLocations: location.service_locations },
      ServiceIdentifiers.DRCHRONO.id
    )
  );

const hasAccessToNonTelemedLocation = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => !location.is_telemed);

const hasAccessToPhysicalLocation = (login: AccountLogin) => {
  return !!login?.account?.locations?.some((l) => isPhysicalLocation(l));
};

const hasAccessToLocationWithMobileCheckinEnabled = (login: AccountLogin) =>
  ((login && login.account && login.account.locations) || []).some((location) =>
    isMobileCheckinEnabled(location)
  );

const hasAccessToLocationWithProviderDesktopNotifications = (login: AccountLogin) =>
  hasAccessToTelemedLocation(login) || hasAccessToLocationWithMobileCheckinEnabled(login);

const hasAccessToLocationWithPosEnabled = (login: AccountLogin) =>
  !!login?.account?.locations?.some((location) => location.is_pos_enabled);

const isSolvEmployeeAccount = (login: AccountLogin) =>
  !!login?.account?.email?.includes('@solvhealth.com');

const isAuthorizedBulkUpdateUser = (login: AccountLogin) => isSolvEmployeeAccount(login);

const isProviderGroupUser = (login: AccountLogin) =>
  !!login?.account?.locations?.some?.(isProviderGroupLocation) &&
  !(isSolvEmployee(login) || isAdmin(login));

export {
  getGroupsIds,
  getGroupsLocations,
  isManager,
  isNormalManager,
  isCallCenterManager,
  isMultiCareUser,
  isAdmin,
  isSolvEmployee,
  isBackOfficeUser,
  isChildrensUrgentCareUser,
  isBilling,
  isFrontOfficeUser,
  isProvider,
  isItAdmin,
  isLoggedIn,
  isNextCareSolvTestingUser,
  isNextCareUser,
  isNextCareCallCenter,
  isAllianceUser,
  isRenownUser,
  isPopCarePlusImmediateCareUser,
  isPortlandUrgentCareUser,
  isRenownTrainingUser,
  isCallCenterRep,
  isUrgentCareForKidsUser,
  isLittleSpursUser,
  isFormationHealthUser,
  isMiExpressCareUser,
  isCityLifeHealthUser,
  requiresPasswordReset,
  expiredPasswordRequiresReset,
  isCitydocUser,
  isInGroup,
  hasAccessToLocation,
  hasAccessToSmartCaresLocations,
  hasAccessToLocationWithPaperwork,
  hasAccessToLocationWithKioskCustomQuestions,
  hasAccessToLocationWithPayments,
  hasAccessToLocationWithConsent,
  hasAccessToLocationWithPaperlessHandoff,
  hasAccessToLocationWithVideoVisitHandoff,
  isLabResultsAccessEnabled,
  hasAccessToPhysicalLocation,
  hasAccessToTelemedLocation,
  hasAccessToNonTelemedLocation,
  hasAccessToLocationWithPosEnabled,
  hasAccessToLocationWithMobileCheckinEnabled,
  isSolvEmployeeAccount,
  isWestmedUser,
  isUrgentTeamUser,
  isBetterMedUser,
  isAuthorizedBulkUpdateUser,
  isSmartCareUser,
  hasAccessToLocationWithProviderDesktopNotifications,
  isProviderGroupUser,
  isMedstarUser,
  isSolvExpressLocation,
  hasAccessToDrChronoLocation,
};
