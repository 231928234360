import fetch from 'isomorphic-fetch';
import { isEmptyObject } from '../util/object';
import { isEmptyString } from '../util/string';
import {
  handleResponse,
  onValidResponse,
  onValidResponseFull,
  onValidResponseWithCallbacks,
  onException,
  onExceptionWithCallback,
} from './util';
import { getOptions, postOptions, patchOptions, deleteOptions } from './requestOptions';
import RequestManager from './requestManager';
import { log } from '../logger/log';
import { addAuthTrackingParams } from '../auth';

export const FILTER_WILDCARD_CHARACTER = '%';
export const DAPI_DATE_RANGE_FILTER_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DAPI_CALENDAR_DATE_RANGE_FILTER_FORMAT = 'YYYY-MM-DD';
export const DAPI_DATE_FORMAT = 'Y-MM-DD';
export const TIME_NOW = 'NOW';

/**
 * Used for fetching and processing PDFs, jpegs, and anything else that is sent as binary data.
 * There are specific use cases in the facesheet such as downloading the insurance cards, or downloading
 * paperwork responses. */
export const apiGetBlob = (url) =>
  RequestManager.makeRequest(async () => {
    const options = await getOptions();
    return fetch(addAuthTrackingParams(url), options);
  }).then((req) => req.blob());

export const apiGetJson = (url) =>
  RequestManager.makeRequest(async () => {
    const options = await getOptions();
    return fetch(addAuthTrackingParams(url), options);
  }).then((req) => req.json());

export const apiPostJson = (url, postData) =>
  RequestManager.makeRequest(async () => {
    const options = await postOptions(postData);
    return fetch(url, options);
  }).then((req) => req.json());

export const apiPostRaw = (url, postData) =>
  RequestManager.makeRequest(async () => {
    const options = await postOptions(postData);
    return fetch(url, options);
  });

export const apiPatchJson = (url, patchData) =>
  RequestManager.makeRequest(async () => {
    const options = await patchOptions(patchData);
    return fetch(url, options);
  }).then((req) => req.json());

export const apiDeleteJson = (url, deleteData) =>
  RequestManager.makeRequest(async () => {
    const options = await deleteOptions(deleteData);
    return fetch(url, options);
  }).then((req) => req.json());

export const apiGetJsonBlocking = async (url) =>
  await RequestManager.makeRequest(async () => {
    const options = await getOptions();
    return fetch(url, options);
  }).then((req) => req.json());

export const apiPostJsonBlocking = async (url, postData) =>
  await RequestManager.makeRequest(async () => {
    const options = await postOptions(postData);
    return fetch(url, options);
  }).then((req) => req.json());

export const apiGetDispatchable = (url, onSuccess, onError) => (dispatch) =>
  apiGetJson(url)
    .then((json) => onValidResponse(json, dispatch, onSuccess, onError))
    .catch((e) => onException(e, dispatch, onError));

export const apiGetDispatchablePaginated =
  (url, onSuccess, onError, limitResultsPerPage = 100) =>
  (dispatch) =>
    apiGetJson(`${url}&page=1&limit=${limitResultsPerPage}`)
      .then((json) => {
        if (!isEmptyObject(json) && !isEmptyObject(json.data) && !isEmptyObject(json.data.page)) {
          const totalResultsCount = json.data.page.results_count;
          if (totalResultsCount !== 0 && json.data.results.length < totalResultsCount) {
            // Need to use pagination
            const nNeededPages = Math.ceil(totalResultsCount / limitResultsPerPage);
            const promises = [];
            for (let page = 2; page <= nNeededPages; page++) {
              promises.push(apiGetJson(`${url}&page=${page}&limit=${limitResultsPerPage}`));
            }

            Promise.all(promises)
              .then((jsonResponses) => {
                const results = [...json.data.results];
                jsonResponses.forEach((response) => {
                  results.push(...response.data.results);
                });
                const aggregatedJson = { ...json };
                aggregatedJson.data.results = results;
                dispatch(onValidResponse(aggregatedJson, dispatch, onSuccess, onError));
              })
              .catch((e) => onException(e, dispatch, onError));
          } else {
            dispatch(onValidResponse(json, dispatch, onSuccess, onError));
          }
        }
      })
      .catch((e) => onException(e, dispatch, onError));

export const apiGetDispatchableIgnoreFailures = (url, onSuccess) => (dispatch) =>
  apiGetJson(url).then((json) => {
    if (json && json.data) {
      dispatch(onSuccess(json.data));
    }
  });

export const apiPostDispatchable = (url, postData, onSuccess, onError) => (dispatch) =>
  apiPostJson(url, postData)
    .then((json) => onValidResponse(json, dispatch, onSuccess, onError))
    .catch((e) => onException(e, dispatch, onError));

export const apiPatchDispatchable = (url, patchData, onSuccess, onError, source) => {
  if (!source) {
    log.error('Missing "source" parameter for patch request');
  }

  const patchDataWithSource = { ...patchData, updated_source: source };
  return (dispatch) =>
    apiPatchJson(url, patchDataWithSource)
      .then((json) => onValidResponse(json, dispatch, onSuccess, onError))
      .catch((e) => onException(e, dispatch, onError));
};

export const apiPatchDispatchableWithoutSource =
  (url, patchData, onSuccess, onError) => (dispatch) =>
    apiPatchJson(url, patchData)
      .then((json) => onValidResponse(json, dispatch, onSuccess, onError))
      .catch((e) => onException(e, dispatch, onError));

export const apiDeleteDispatchable = (url, deleteData, onSuccess, onError) => (dispatch) =>
  apiDeleteJson(url, deleteData)
    .then((json) => onValidResponse(json, dispatch, onSuccess, onError))
    .catch((e) => onException(e, dispatch, onError));

export const apiGetDispatchableBlocking = (url, onSuccess, onError) => (dispatch) =>
  apiGetJsonBlocking(url)
    .then((json) => onValidResponse(json, dispatch, onSuccess, onError))
    .catch((e) => onException(e, dispatch, onError));

export const apiPostDispatchableBlocking = (url, postData, onSuccess, onError) => (dispatch) =>
  apiPostJsonBlocking(url, postData)
    .then((json) => onValidResponse(json, dispatch, onSuccess, onError))
    .catch((e) => onException(e, dispatch, onError));

export const apiGetDispatchableFullResponse = (url, onSuccess, onError) => (dispatch) =>
  apiGetJson(url)
    .then((json) => onValidResponseFull(json, dispatch, onSuccess, onError))
    .catch((e) => onException(e, dispatch, onError));

export const apiPostDispatchableFullResponse = (url, postData, onSuccess, onError) => (dispatch) =>
  apiPostJson(url, postData)
    .then((json) => onValidResponseFull(json, dispatch, onSuccess, onError))
    .catch((e) => onException(e, dispatch, onError));

export const apiPostJsonDispatchableWithCallbacks =
  (url, postData, onSuccessAction, onErrorAction, onSuccessCallback, onErrorCallback) =>
  (dispatch) =>
    apiPostJson(url, postData)
      .then((json) =>
        onValidResponseWithCallbacks(
          json,
          dispatch,
          onSuccessAction,
          onErrorAction,
          onSuccessCallback,
          onErrorCallback
        )
      )
      .catch((e) => onExceptionWithCallback(e, dispatch, onErrorAction, onErrorCallback));

export const getPaginationValues = (response) => {
  const currentPage = response.page.page;

  return {
    currentPage,
    hasMorePages: Boolean(response.results.length),
  };
};

/**
 * Return filters for a multitude of tables.
 *
 * @param {Object} filters
 * @return {string}
 */
export const getMultiFiltersString = (filters) => {
  let filtersString = '';
  if (filters) {
    const foundFilters = Object.entries(filters).flatMap(([table, filter]) =>
      Object.entries(filter).map(
        ([column, filterValue]) => `${table}.${column}:${encodeURIComponent(filterValue)}`
      )
    );
    filtersString = foundFilters.join(';');
  }
  return filtersString;
};

export const getFilterString = (table, filters) => {
  let filterString = '';
  if (filters) {
    const filterStrings = [];
    for (const k in filters) {
      if (filters.hasOwnProperty(k) && !isEmptyString(filters[k])) {
        filterStrings.push(`${table}.${k}:${encodeURIComponent(filters[k])}`);
      }
    }

    filterString = `filters=${filterStrings.join(';')}`;
  }

  return filterString;
};

export const apiGet = (url) => apiGetJson(url).then((json) => handleResponse(json));

export const apiPost = (url, data) => apiPostJson(url, data).then((json) => handleResponse(json));

export const apiPatch = (url, data) => apiPatchJson(url, data).then((json) => handleResponse(json));

export const apiDelete = (url, data) =>
  apiDeleteJson(url, data).then((json) => handleResponse(json));
