/* eslint-disable no-unused-expressions */
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { locationError, receiveLocation } from '../actions/location';
import {
  BOOKING_STATUS_CONFIRMED,
  ORIGIN_KIOSK_SIGN_IN,
  PaperworkTypes,
  PAPERWORK_IN_PROGRESS,
} from '../constants';
import { analyticsIdentify, analyticsTrackEvent } from '../core/analytics';
import { SIGN_IN_API_NEW_BOOKING } from '../core/analytics/events';
import {
  fireGoogleAnalytics,
  GA_EVENT_ACTION_BOOKING_CREATED,
  GA_EVENT_CATEGORY_WORKFLOW,
  GA_EVENT_TYPE_EVENT,
} from '../core/analytics/trackingForPartners';
import { apiGet, apiPatch, apiPost } from '../core/dapi';
import { getBookingByIdUrl } from '../core/dapi/bookings';
import { getLocationUrl } from '../core/dapi/location';
import { buildSignInPostData, getNewBookingUrl } from '../core/dapi/newBooking';
import { getCreatePaperworkResponsesUrl } from '../core/dapi/paperworkResponses';
import history from '../core/history';
import { emptyFunction } from '../core/util/function';
import { isKioskCustomQuestionsEnabled, isPaperworkEnabled } from '../core/util/location';
import { isEmptyObject } from '../core/util/object';
import {
  fetchLocationPaperworkFields as fetchLocationPaperworkFieldsAction,
  selectors as paperworkSelectors,
} from '../ducks/paperwork';
import { getPresentationMode } from '../routes/welcome/presentationMode';
import { fetchLocationPaperworkFields as fetchLocationPaperworkFieldsSaga } from '../sagas/Paperwork';
import { getLocation } from '../selectors/location';

export class KioskSagas {
  static CHECK_IN = 'CHECK_IN';
  static CHECK_IN_OFFICE = 'CHECK_IN_OFFICE';
  static CONFIRM_BOOKINGS = 'CONFIRM_BOOKINGS';
  static POST_SIGN_IN = 'POST_SIGN_IN';
}

function* confirmBookings({
  bookingIds,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  onCompletion = emptyFunction,
}) {
  const patchData = {
    status: BOOKING_STATUS_CONFIRMED,
    check_in_source: getPresentationMode(),
    updated_source: ORIGIN_KIOSK_SIGN_IN,
  };

  try {
    yield all(
      bookingIds.map((bookingId) => call(apiPatch, getBookingByIdUrl(bookingId), patchData))
    );
    onSuccess?.();
  } catch (err) {
    onError?.();
  } finally {
    onCompletion?.();
  }
}

function* checkInOffice({
  bookingId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  onCompletion = emptyFunction,
}) {
  let bookingSubmitted = false;
  function* actuallySubmitBooking() {
    yield call(confirmBookings, {
      bookingIds: [bookingId],
    });
    history.push('/success');
    bookingSubmitted = true;
  }

  try {
    const bookingsFromLookup = yield select((state) => state?.lookUp?.bookings ?? []);
    const activeBooking = bookingsFromLookup.find((booking) => bookingId === booking.id);
    if (!activeBooking) {
      yield call(actuallySubmitBooking);
      onSuccess();
      return;
    }

    const locationId = activeBooking.location_id;

    // Fetch some data so we can figure out if we need to administer a questionnaire.
    yield all([
      call(function* fetchTheLocation() {
        try {
          const result = yield call(apiGet, getLocationUrl(locationId, ORIGIN_KIOSK_SIGN_IN));
          yield put(receiveLocation(result));
        } catch (error) {
          yield put(locationError(error));
        }
      }),
      call(
        fetchLocationPaperworkFieldsSaga,
        fetchLocationPaperworkFieldsAction(locationId, {
          fieldsType: PaperworkTypes.KIOSK,
        })
      ),
    ]);

    const bookingLocation = yield select(getLocation);
    const customKioskQuestions = yield select(paperworkSelectors.getCustomKioskLpfs);

    const shouldAdministerQuestionnaire = () =>
      !!(customKioskQuestions.length && isKioskCustomQuestionsEnabled(bookingLocation));

    if (shouldAdministerQuestionnaire()) {
      history.push(`/questionnaire/check-in/${bookingId}`);
      onSuccess();
      return;
    }

    yield call(actuallySubmitBooking);

    onSuccess();
    return;
  } catch (err) {
    if (!bookingSubmitted) {
      yield call(actuallySubmitBooking);
    }
    onError();
  } finally {
    onCompletion();
  }
}

function* checkInNonOffice({ bookingIds, location, paperwork, onFinish = emptyFunction }) {
  if (!isEmptyObject(paperwork.paperworkResponsesToSubmit)) {
    const responseFields = { ...paperwork.paperworkResponsesToSubmit };

    if (isPaperworkEnabled(location)) {
      responseFields.paperworkStatus = PAPERWORK_IN_PROGRESS;
    }

    const paperworkResponsesPostData = {
      type_: PaperworkTypes.KIOSK,
      response_fields: responseFields,
    };

    yield all(
      bookingIds.map((bookingId) =>
        call(apiPost, getCreatePaperworkResponsesUrl(), {
          ...paperworkResponsesPostData,
          booking_id: bookingId,
        })
      )
    );
  }

  const patchData = {
    status: BOOKING_STATUS_CONFIRMED,
    check_in_source: getPresentationMode(),
    updated_source: ORIGIN_KIOSK_SIGN_IN,
  };

  yield all(bookingIds.map((bookingId) => call(apiPatch, getBookingByIdUrl(bookingId), patchData)));

  onFinish();
}

function* postSignIn({ props, onSuccess, bookingFailed }) {
  try {
    const postData = buildSignInPostData(props);
    const response = yield call(apiPost, getNewBookingUrl(), postData);

    if (!isEmptyObject(props.paperwork.paperworkResponsesToSubmit)) {
      const responseFields = { ...props.paperwork.paperworkResponsesToSubmit };
      if (isPaperworkEnabled(props.location)) {
        responseFields.paperworkStatus = PAPERWORK_IN_PROGRESS;
      }
      const paperworkResponsesPostData = {
        booking_id: response.booking_id,
        type_: PaperworkTypes.KIOSK,
        response_fields: responseFields,
      };
      yield call(apiPost, getCreatePaperworkResponsesUrl(), paperworkResponsesPostData);
    }

    analyticsIdentify(response.account_id, postData);
    analyticsTrackEvent(SIGN_IN_API_NEW_BOOKING, { booking_id: response.booking_id });
    fireGoogleAnalytics(
      props.location,
      GA_EVENT_ACTION_BOOKING_CREATED,
      GA_EVENT_TYPE_EVENT,
      GA_EVENT_CATEGORY_WORKFLOW,
      response.booking_id
    );
    yield put(onSuccess(response));
  } catch (e) {
    yield put(bookingFailed(e));
  }
}

export default function* rootSaga() {
  yield takeEvery(KioskSagas.CONFIRM_BOOKINGS, confirmBookings);
  yield takeEvery(KioskSagas.CHECK_IN, checkInNonOffice);
  yield takeEvery(KioskSagas.CHECK_IN_OFFICE, checkInOffice);
  yield takeEvery(KioskSagas.POST_SIGN_IN, postSignIn);
}
