import {
  isCallCenterRep,
  isNextCareUser,
  isSolvEmployee,
  isSolvExpressLocation,
} from '../core/session';
import { EMPTY_ARRAY } from '../core/util/array';
import { shouldShowFeature } from '../core/viewCustomization';
import { FeatureName } from '../core/viewCustomization/featureNames';
import { RootState } from '../types/RootState';

export const getLogin = (reduxState: RootState) => reduxState?.login ?? null;

export const getShouldShowFeature = (reduxState: RootState, featureName: FeatureName) => {
  const login = getLogin(reduxState);
  if (!login) return false;
  return shouldShowFeature(login, featureName);
};

export const getIsNextCareUser = (reduxState: RootState) => {
  const login = getLogin(reduxState);
  if (!login) return false;
  return isNextCareUser(login);
};

export const getIsSolvEmployee = (reduxState: RootState) => isSolvEmployee(getLogin(reduxState));

export const isSolvExpressLocationSelected = (reduxState: RootState, locationIds: string[]) => {
  const login = getLogin(reduxState);
  return !!login?.account?.locations?.some?.(
    (location) => locationIds.includes(location.location_id) && isSolvExpressLocation(location)
  );
};

export const getLastCompletedNps = (reduxState: RootState): string | null => {
  return getLogin(reduxState)?.account?.last_completed_nps ?? null;
};

export const getIsCallCenterRep = (reduxState: RootState) => {
  const login = getLogin(reduxState);
  if (!login) return false;
  return isCallCenterRep(login);
};

export const getUserAccountId = (reduxState: RootState): string | null => {
  const login = getLogin(reduxState);
  return login?.account?.id ?? null;
};

export const getUserRoles = (reduxState: RootState): string[] => {
  const login = getLogin(reduxState);
  return login?.account?.roles ?? (EMPTY_ARRAY as never[]);
};

export const getUserGroups = (reduxState: RootState): string[] => {
  const login = getLogin(reduxState);
  return login?.account?.groups?.map((group) => group.group_id) ?? (EMPTY_ARRAY as never[]);
};
