import { DAPI_TOKEN } from '../../config';
import { LoginToken } from '../../types/RootState';

export const setAuth = (token: LoginToken) => {
  token.meta = {
    last_refresh: Date.now(),
  };
  if (typeof localStorage === 'undefined') {
    return undefined;
  }
  localStorage.setItem('dapi_authorization', JSON.stringify(token));
};

export const getAuth = (): LoginToken | undefined => {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }
  const item = localStorage.getItem('dapi_authorization');
  if (!item) {
    return undefined;
  }
  return JSON.parse(item);
};

export const getAuthHeader = () => `Bearer ${getAuth()?.access_token}`;

export const removeAuth = () => {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }
  localStorage.removeItem('dapi_authorization');
};

export const getClinicAccountId = (): string | undefined => {
  return getAuth()?.clinic_account_id;
};

export const getDapiAuthHeaders = () => {
  const auth = getAuth();
  if (!auth) {
    // Public dapi token
    return { Authorization: `Bearer ${DAPI_TOKEN}` };
  }
  return { Authorization: `Bearer ${auth.access_token}` };
};

export const addAuthTrackingParams = (url: string) => {
  return url;
};
