import { ANALYTICS } from '../../config';
import { isClientSide } from '../util/system';
import { GenericObject } from '../util/generics';

const options = {
  integrations: ANALYTICS.integrations,
};

const isAnalyticsEnabled = () => Boolean(ANALYTICS.enabled && isClientSide() && window.analytics);

const analyticsTrackPage = (category: string, name: string, properties: GenericObject) => {
  if (isAnalyticsEnabled()) {
    const props = {
      ...properties,
      manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      title: '',
    };
    window.analytics.page(category, name, props, options);
  }
};

const analyticsTrackEvent = (event: string, properties: GenericObject = {}) => {
  if (isAnalyticsEnabled()) {
    window.analytics.track(
      event,
      {
        ...properties,
        manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      },
      options
    );
  }
};

const analyticsTrackFormErrors = (event: string, errors: GenericObject) => {
  if (isAnalyticsEnabled()) {
    const properties = {
      ...errors,
      manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      fields: Object.keys(errors).join(','),
    };
    analyticsTrackEvent(event, properties);
  }
};

const analyticsIdentify = (accountId: string, bookingPostData: GenericObject) => {
  if (isAnalyticsEnabled()) {
    window.analytics.identify(
      accountId,
      {
        email: bookingPostData.account.email,
        first_name: bookingPostData.account.first_name,
        last_name: bookingPostData.account.last_name,
        phone: bookingPostData.account.phone,
        origin: bookingPostData.booking.origin,
        reason: bookingPostData.booking.reason_for_visit,
        insurer_type: bookingPostData.insurance_profile
          ? bookingPostData.insurance_profile.insurer_type
          : null,
        insurer_code: bookingPostData.insurance_profile
          ? bookingPostData.insurance_profile.insurer_code
          : null,
        manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      },
      options
    );
  }
};

export { analyticsTrackPage, analyticsTrackEvent, analyticsIdentify, analyticsTrackFormErrors };
