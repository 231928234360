import {
  DAPI_CACTUS_HOST,
  DAPI_CLOUDFRONT_HOST,
  DAPI_HOST,
  DEFAULT_SEARCH_RADIUS_IN_MILES,
  METERS_IN_A_MILE,
} from '../../config';
import { ORIGIN_QUEUE, PLATFORM_TYPE_PROVIDER_GROUP, VERSION_TWO } from '../../constants';
import { getFilterString } from '.';
import { isEmpty } from '../util/empty';
import { queryStringFromObject } from '../util/string';
import { safeGet } from '../util/object';

const SETTINGS_LOCATIONS_FIELDS = 'settingsLocationsFields';
const KIOSK_LOOKUP_FIELDS = 'kioskLookupFields';
const QUEUE_LOCATIONS_FIELDS = 'queueLocationsFields';
const ADD_PATIENT_MODAL_FIELDS = 'addPatientModalFields';
const PROVIDER_GROUP_PRACTICE_LOCATIONS_FIELDS = 'pgPracticeLocationsFields';
const GROUP_EDIT_FIELDS = 'groupEditFields';
const REVIEW_MODERATION_FIELDS = 'reviewModerationFields';

const LOCATION_SEARCH_FIELDS = [
  'id',
  'display_name_primary',
  'display_name_secondary',
  'display_address',
  'address',
  'subpremise',
  'city',
  'state',
  'zip_code',
  'phone',
  'is_telemed',
  'time_zone',
  'groups',
  'wait_list_count',
  'lat_long',
  'distance_from_current_location',
  'hours',
];

const fieldSets = {
  [SETTINGS_LOCATIONS_FIELDS]: [
    'address',
    'appointment_interval',
    'appointment_interval_reservations',
    'appointment_slots',
    'city',
    'disable_reservations_until',
    'display_name_primary',
    'display_name_secondary',
    'group_id',
    'hours',
    'hours_default',
    'id',
    'is_solv_partner',
    'is_test_location',
    'location_id_numeric',
    'payment_account_id',
    'platform_type',
    'special_operating_hours',
    'state',
    'time_zone',
    'zip_code',
    'group_id',
    'location_id_numeric',
    'wait_time',
  ],
  [GROUP_EDIT_FIELDS]: ['id', 'display_name_primary', 'display_name_secondary'],
  [REVIEW_MODERATION_FIELDS]: [
    'id',
    'display_name_primary',
    'display_name_secondary',
    'is_solv_partner',
  ],
  [KIOSK_LOOKUP_FIELDS]: [
    'id',
    'display_name_primary',
    'display_name_secondary',
    'is_solv_partner',
    'is_test_location',
    'offboarded_date',
  ],
  [QUEUE_LOCATIONS_FIELDS]: [
    'address',
    'booking_url',
    'city',
    'display_address',
    'display_name_primary',
    'display_name_secondary',
    'distance_from_current_location',
    'groups',
    'id',
    'image_links',
    'is_booking_widget_address_required',
    'is_asap_telemed_enabled',
    'is_birth_sex_required',
    'is_book_ahead_on_walk_in_slots',
    'is_canned_text_chat_only_enabled',
    'is_consent_enabled',
    'is_kiosk_custom_questions_enabled',
    'is_mobile_check_in_enabled',
    'is_paperless_handoff_enabled',
    'is_paperwork_enabled',
    'is_paperwork_required',
    'is_payments_enabled',
    'is_payments_required',
    'is_photo_id_back_required',
    'is_photo_id_front_required',
    'is_photo_id_upload_enabled',
    'is_smooch_enabled',
    'is_standalone_telemed',
    'is_telemed',
    'is_test_location',
    'is_video_visit_handoff_enabled',
    'lat_long',
    'offboarded_date',
    'office_id',
    'phone',
    'platform_type',
    'service_locations',
    'services_obj',
    'services',
    'specialties',
    'state',
    'subpremise',
    'time_zone',
    'tracking_properties',
    'website',
    'zip_code',
    'booking_widget_languages',
    'package_name',
    'is_performance_pricing_enabled',
    'live_date',
    'performance_pricing_monthly_budget',
    'has_boost',
    'twilio_phone',
    'contracted_booking_page_engagement',
    'performance_pricing_overage_cap',
    'performance_pricing_overage_bpe_cost',
    'is_family_bookings_enabled',
    'is_email_required_for_booking_widget',
    'is_custom_quick_reply_enabled',
  ],
  [ADD_PATIENT_MODAL_FIELDS]: ['hours', 'wait_list_count'],
  [PROVIDER_GROUP_PRACTICE_LOCATIONS_FIELDS]: [
    'id',
    'display_name_primary',
    'display_name_secondary',
  ],
};

export const buildGenericFieldsQueryParam = (fieldNamesList = []) =>
  `&fields=${fieldNamesList.join(',')}`;

export const buildFieldsQueryParam = (fieldSet, additionalFields = []) =>
  buildGenericFieldsQueryParam(fieldSets[fieldSet].concat(additionalFields));

const getLocationsUrl = (opts = {}) => {
  const url = `${DAPI_HOST}/v3/locations?filters=locations.is_bookable:true`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getIsSolvPartnerLocationsUrl = (opts = {}) => {
  const url = `${DAPI_HOST}/${
    safeGet(opts)('version') || 'v3'
  }/locations?filters=locations.is_solv_partner:true`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getPartnerAndNonPartnerLocationsUrl = (opts = {}) => {
  const url = `${DAPI_HOST}/${opts?.version || 'v3'}/locations?`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getNonPartnerLocationsUrl = (opts = {}) => {
  const url = `${DAPI_HOST}/${
    opts?.version || 'v3'
  }/locations?filters=locations.is_solv_partner:false`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getIsSolvEmployeeLocationsUrlMultipleParams = (searchParams, opts = {}) => {
  const locationSearchParams = { ...searchParams };
  delete locationSearchParams.group_id;
  delete locationSearchParams.zip_code;

  let url = `${DAPI_HOST}/v3/locations?${getFilterString('locations', locationSearchParams)}`;

  if (searchParams.group_id) {
    url += `&group_ids=${searchParams.group_id}`;
  }

  if (searchParams.zip_code) {
    url += `&zip_code=${searchParams.zip_code}`;
  }

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getLocationUrl = (locationId, origin = ORIGIN_QUEUE, version = VERSION_TWO, opts = {}) => {
  const url = `${DAPI_HOST}/${version}/locations/${locationId}?origin=${origin}`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getCachedLocationUrl = (locationId, origin = ORIGIN_QUEUE, version = VERSION_TWO) =>
  `${DAPI_CLOUDFRONT_HOST}/${version}/locations/${locationId}?origin=${origin}`;

const getLocationsSearchUrl = (
  searchParameters,
  searchRadius = DEFAULT_SEARCH_RADIUS_IN_MILES * METERS_IN_A_MILE,
  origin = ORIGIN_QUEUE
) => {
  let url =
    `${DAPI_CACTUS_HOST}/v4/locations?limit=50&is_viewable=true&radius=${searchRadius}` +
    `&origin=${origin}&filters=locations.is_telemed:false&fields=${LOCATION_SEARCH_FIELDS}`;
  if (searchParameters.latitude && searchParameters.longitude) {
    url = `${url}&lat=${searchParameters.latitude}&long=${searchParameters.longitude}`;
  } else if (searchParameters.zipCode) {
    url = `${url}&zip_code=${searchParameters.zipCode}`;
  } else {
    return null;
  }

  if (searchParameters.groupIds) {
    url = `${url}&group_ids=${searchParameters.groupIds}`;
  }

  if (searchParameters.isNotOffBoarded) {
    url = `${url}&is_not_off_boarded=${searchParameters.isNotOffBoarded}`;
  }
  return url;
};

const getLocationsByClinicAccountIdUrl = (clinicAccountId, opts = {}) => {
  const url = `${DAPI_HOST}/v4/locations?clinic_account_id=${clinicAccountId}`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet, opts.additionalFields)}`;
  }

  return url;
};

const getLocationsByOfficeIdUrl = (officeId) => `${DAPI_HOST}/v4/locations?office_ids=${officeId}`;

const getLocationsByGroupsUrl = (groupIds, opts = {}) =>
  `${getIsSolvPartnerLocationsUrl(opts)}&group_ids=${groupIds}`;

const getNonPartnerAndPartnerLocationsByGroupsUrl = (groupIds, opts = {}) =>
  `${getPartnerAndNonPartnerLocationsUrl(opts)}&group_ids=${groupIds}`;

const getPatchLocationDataUrl = (locationId) => `${DAPI_HOST}/v1/locations/${locationId}`;

const getPatchLocationUclBrandsAssignmentUrl = (locationId) =>
  `${DAPI_HOST}/v1/locations/${locationId}/ucl-brand-assignment`;

const getLocationSpecialOperatingHoursUrl = (locationId, hoursId = '') =>
  `${DAPI_HOST}/v1/locations/${locationId}/operating-hours-special/${hoursId}`;

const getLocationSlotsUrl = (locationId, origin, onDate) =>
  `${DAPI_HOST}/v1/locations/${locationId}/slots?origin=${origin}&on_date=${onDate}`;

const getLocationReservationHoursUrl = (locationId, reservationHoursId = '') =>
  `${DAPI_HOST}/v1/locations/${locationId}/reservation-hours/${reservationHoursId}`;

const getLocationAvailabilityRecommendationsUrl = (locationId) =>
  `${DAPI_HOST}/v1/locations/${locationId}/availability-recommendations`;

const getLocationOperatingHoursUrl = (locationId) =>
  `${DAPI_HOST}/v1/locations/${locationId}/operating-hours`;

const getLocationBookingCodesUrl = (locationId) =>
  `${DAPI_HOST}/v1/locations/${locationId}/booking-codes`;

const addLocationUrl = () => `${DAPI_CACTUS_HOST}/v1/locations`;

const getProcessImagesUrl = (entityId, entityType) =>
  `${DAPI_CACTUS_HOST}/v1/${entityType}s/${entityId}/process-images`;

const getInvalidateCloudfrontCacheUrl = (locationId) =>
  `${DAPI_HOST}/v1/locations/${locationId}/invalidate-cloudfront-cache`;

const getTelemedLocationSearchUrl = (groupId) =>
  `${DAPI_CACTUS_HOST}/v4/locations?limit=50&group_ids=${groupId}&filters=locations.is_telemed:true&fields=${LOCATION_SEARCH_FIELDS}`;

const getLocationCategoriesUrl = () => `${DAPI_HOST}/v1/location-categories`;

const getLocationServicePreferencesUrl = (queryParams) => {
  const queryString = isEmpty(queryParams) ? '' : `?${queryStringFromObject(queryParams)}`;
  return `${DAPI_HOST}/v1/locations/service-preferences${queryString}`;
};

const getPhysicalLocationsInStateByGroupUrl = (stateCode, groupIds) =>
  `${getIsSolvPartnerLocationsUrl({
    version: 'v4',
  })};locations.is_telemed:false;locations.state:${stateCode}&group_ids=${groupIds}`;

const getTelemedLocationsInStateByGroupUrl = (stateCode, groupIds) =>
  `${getIsSolvPartnerLocationsUrl({
    version: 'v4',
  })};locations.is_telemed:true;locations.state:${stateCode}&group_ids=${groupIds}`;

const getUpdateLocationAssociationsUrl = () => `${DAPI_HOST}/v1/locations/location-associations`;

const getProviderGroupLocationsUrl = () =>
  `${getIsSolvPartnerLocationsUrl({
    version: 'v4',
  })};locations.platform_type:${PLATFORM_TYPE_PROVIDER_GROUP}&fields=${
    fieldSets[PROVIDER_GROUP_PRACTICE_LOCATIONS_FIELDS]
  }`;

export {
  getLocationUrl,
  getCachedLocationUrl,
  getLocationsUrl,
  getIsSolvPartnerLocationsUrl,
  getNonPartnerLocationsUrl,
  getIsSolvEmployeeLocationsUrlMultipleParams,
  getLocationsByGroupsUrl,
  getNonPartnerAndPartnerLocationsByGroupsUrl,
  getLocationsSearchUrl,
  getLocationSlotsUrl,
  getLocationSpecialOperatingHoursUrl,
  getPatchLocationUclBrandsAssignmentUrl,
  getLocationReservationHoursUrl,
  getPatchLocationDataUrl,
  getLocationOperatingHoursUrl,
  getLocationBookingCodesUrl,
  addLocationUrl,
  KIOSK_LOOKUP_FIELDS,
  SETTINGS_LOCATIONS_FIELDS,
  QUEUE_LOCATIONS_FIELDS,
  ADD_PATIENT_MODAL_FIELDS,
  GROUP_EDIT_FIELDS,
  REVIEW_MODERATION_FIELDS,
  getProcessImagesUrl,
  getLocationAvailabilityRecommendationsUrl,
  getInvalidateCloudfrontCacheUrl,
  getTelemedLocationSearchUrl,
  getLocationsByOfficeIdUrl,
  getLocationsByClinicAccountIdUrl,
  getLocationCategoriesUrl,
  getLocationServicePreferencesUrl,
  getPhysicalLocationsInStateByGroupUrl,
  getTelemedLocationsInStateByGroupUrl,
  getUpdateLocationAssociationsUrl,
  getProviderGroupLocationsUrl,
};
