import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Spin as AntSpin } from 'antd';
import { ANTD_SIZE_SMALL, ANTD_SIZE_DEFAULT, ANTD_SIZE_LARGE } from '../constants';

export default class Spin extends PureComponent {
  render = () => (
    <Row
      align="middle"
      justify="center"
      type="flex"
      style={{
        backgroundColor: this.props.backgroundColor,
        minHeight: this.props.minHeight,
      }}
    >
      <AntSpin size={this.props.size} />
    </Row>
  );
}

Spin.defaultProps = {
  backgroundColor: '#fff',
  minHeight: '100vh',
  size: ANTD_SIZE_DEFAULT,
};

Spin.propTypes = {
  backgroundColor: PropTypes.string,
  minHeight: PropTypes.string,
  size: PropTypes.oneOf([ANTD_SIZE_SMALL, ANTD_SIZE_DEFAULT, ANTD_SIZE_LARGE]),
};
