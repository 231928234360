import { isEmptyArray } from './array';
import {
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_BACK_OFFICE,
  ACCOUNT_ROLE_BILLING,
  ACCOUNT_ROLE_CALL_CENTER,
  ACCOUNT_ROLE_FRONT_OFFICE,
  ACCOUNT_ROLE_IT_ADMIN,
  ACCOUNT_ROLE_MANAGER,
  ACCOUNT_ROLE_PROVIDER,
  ACCOUNT_ROLE_SOLV_EMPLOYEE,
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_DEACTIVATED,
  AccountRole,
  ACCOUNT_ROLE_CALL_CENTER_MANAGER,
} from '../../constants';
import {
  hasAccessToLocationWithPayments,
  isAdmin,
  isCallCenterManager,
  isMultiCareUser,
  isNextCareUser,
  isSolvEmployee,
} from '../session';
import { isEmptyObject } from './object';
import { Account, AccountLogin } from '../../types/RootState';

const getAccountHolderName = (account: Account) => {
  if (!account) {
    return '';
  }
  return `${account.first_name} ${account.last_name}`;
};

const getGroupsIds = (account: Account) => {
  if (isEmptyArray(account.groups)) {
    return [];
  }

  return account.groups.map((group) => group.group_id);
};

const getLocationIds = (account: Account) => {
  if (isEmptyArray(account.locations)) {
    return [];
  }

  return account.locations.map((location) => location.location_id);
};

const getFriendlyRoleName = (role: AccountRole) => {
  switch (role) {
    case ACCOUNT_ROLE_FRONT_OFFICE:
      return 'Front Office';
    case ACCOUNT_ROLE_BACK_OFFICE:
      return 'Back Office';
    case ACCOUNT_ROLE_PROVIDER:
      return 'Provider';
    case ACCOUNT_ROLE_MANAGER:
      return 'Manager';
    case ACCOUNT_ROLE_CALL_CENTER:
      return 'Call Center';
    case ACCOUNT_ROLE_IT_ADMIN:
      return 'IT Admin';
    case ACCOUNT_ROLE_SOLV_EMPLOYEE:
      return 'Solv Employee';
    case ACCOUNT_ROLE_BILLING:
      return 'Billing';
    case ACCOUNT_ROLE_ADMIN:
      return 'Admin';
    case ACCOUNT_ROLE_CALL_CENTER_MANAGER:
      return 'Call Center Manager';
    default:
      return role;
  }
};

const accountHasPaperworkEnabled = (account: Account) =>
  Boolean(account?.locations.some((accLoc) => accLoc.is_paperwork_enabled));

const accountHasTelemedEnabled = (account: Account) =>
  account.locations.some((accLoc) => accLoc.is_telemed);

const isAllowedToAssignRole = (login: AccountLogin, role: AccountRole) => {
  if (role === ACCOUNT_ROLE_ADMIN) {
    return false;
  }

  if (isAdmin(login)) {
    return true;
  }

  if (isSolvEmployee(login) && role !== ACCOUNT_ROLE_SOLV_EMPLOYEE) {
    return true;
  }

  if (isNextCareUser(login) && role === ACCOUNT_ROLE_BACK_OFFICE) {
    return true;
  }

  if ((isNextCareUser(login) || isMultiCareUser(login)) && role === ACCOUNT_ROLE_CALL_CENTER) {
    return true;
  }

  if (hasAccessToLocationWithPayments(login) && role === ACCOUNT_ROLE_BILLING) {
    return true;
  }

  if (
    isCallCenterManager(login) &&
    (role === ACCOUNT_ROLE_CALL_CENTER || role === ACCOUNT_ROLE_CALL_CENTER_MANAGER)
  ) {
    return true;
  }

  return [
    ACCOUNT_ROLE_FRONT_OFFICE,
    ACCOUNT_ROLE_IT_ADMIN,
    ACCOUNT_ROLE_MANAGER,
    ACCOUNT_ROLE_PROVIDER,
  ].includes(role);
};

const getClinicAccountStatus = (account: Account) =>
  account.active ? ACCOUNT_STATUS_ACTIVE : ACCOUNT_STATUS_DEACTIVATED;

const isInactiveAccount = (account: Account) => !account.active;

const isClinicRole = (account: Account) =>
  [ACCOUNT_ROLE_BACK_OFFICE, ACCOUNT_ROLE_FRONT_OFFICE].some((role) =>
    account.roles.includes(role)
  );

const shouldLogInToPubNub = (roles: Array<AccountRole>) =>
  !roles.includes(AccountRole.ACCOUNT_ROLE_ADMIN) &&
  !roles.includes(AccountRole.ACCOUNT_ROLE_CALL_CENTER);

const getFirstGroupName = (account: Account) => {
  if (isEmptyArray(account.groups)) {
    return '';
  }

  return account.groups[0].name;
};

const getFirstGroupId = (account: Account) => {
  if (isEmptyArray(account?.groups)) {
    return null;
  }

  return account.groups[0].group_id;
};

const getClinicAccountLocationLabelColor = (login: AccountLogin, locationId: string) => {
  if (isEmptyObject(login)) {
    return null;
  }
  const selectedClinicAccountLocation = login.account.locations.find(
    (loc) => loc.location_id === locationId
  );
  return selectedClinicAccountLocation?.location_label_color ?? null;
};

export {
  getAccountHolderName,
  isAllowedToAssignRole,
  getFriendlyRoleName,
  getGroupsIds,
  getFirstGroupId,
  getFirstGroupName,
  getLocationIds,
  getClinicAccountStatus,
  isInactiveAccount,
  isClinicRole,
  accountHasPaperworkEnabled,
  accountHasTelemedEnabled,
  getClinicAccountLocationLabelColor,
  shouldLogInToPubNub,
};
