import { standardizeDate } from '../../../../core/util/date';

export const addIEPrototypeForReadAsBinaryString = () => {
  FileReader.prototype.readAsBinaryString = function(fileData) {
    // eslint-disable-line
    let binary = '';
    const _this = this;
    const pt = _this;
    const reader = new FileReader();

    reader.onload = () => {
      const bytes = new Uint8Array(reader.result);

      bytes.forEach(byte => {
        binary += String.fromCharCode(byte);
      });

      pt.content = binary;
      pt.onload();
    };

    reader.readAsArrayBuffer(fileData);
  };
};

export const handleGenerateErrorCsvRow = (errors, csvData) => {
  const newCsvData = csvData;
  let errorCount = 0;

  errors.forEach(error => {
    newCsvData[`error${++errorCount}`] = error.description;
  });

  errorCount = 0;
  return newCsvData;
};

export const handleGenerateSoftMatchCsvRow = (matches, csvData) => {
  const newCsvData = csvData;

  newCsvData.account_first_name = matches.first_name;
  newCsvData.account_last_name = matches.last_name;

  return newCsvData;
};

export const hasASoftMatch = (responseData, csvData) =>
  responseData.first_name !== csvData.first_name || responseData.last_name !== csvData.last_name;

export const formatInputs = account => {
  const formattedAccountData = {
    ...account,
    first_name: account.first_name.trim(),
    last_name: account.last_name.trim(),
    birth_date: standardizeDate(account.birth_date.trim()),
    appointment_date: standardizeDate(account.appointment_date.trim()),
    location_id: account.location_id.trim(),
    phone: account.phone.trim(),
    email: account.email.trim(),
    due_date: standardizeDate(account.due_date.trim()),
  };

  return formattedAccountData;
};
