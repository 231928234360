import { isEmptyObject } from '../core/util/object';
import { GenericObject } from '../core/util/generics';

const CLINIC_CONFIG: Record<string, GenericObject> = {
  // PAH
  g1PORp: {
    review_minimum_rating: 1,
    max_review_day_age: 7,
    max_number_reviews: 100,
  },
  gNVQog: {
    review_minimum_rating: 1,
    max_review_day_age: 7,
    max_number_reviews: 100,
  },
  pnQ5XA: {
    review_minimum_rating: 1,
    max_review_day_age: 7,
    max_number_reviews: 100,
  },

  // PUCCFW
  gqdkOp: {
    reviews_show_heart_only: true,
  },
  JA68Mg: {
    reviews_show_heart_only: true,
  },
  NA46Og: {
    reviews_show_heart_only: true,
  },
};

const REVIEW_DEFAULT_MINIMUM_RATING = 4;

const UNIVERSITY_URGENT_CARE_LOCATION_ID = 'A4dVxA';

const SMART_CARE_ANAHEIM = 'gbYmQp';
const SMART_CARE_BOSTON = 'gQOYzA';
const SMART_CARE_DALLAS = 'g1PlQR';
const SMART_CARE_VIRTUAL_VISIT = 'AvykR0';
const SMART_CARE_NEW_YORK = '0kmerp';
const SMART_CARE_SF = 'pnrk5p';

const SMART_CARE_LOCATION_IDS = [
  SMART_CARE_ANAHEIM,
  SMART_CARE_BOSTON,
  SMART_CARE_DALLAS,
  SMART_CARE_VIRTUAL_VISIT,
  SMART_CARE_NEW_YORK,
  SMART_CARE_SF,
];

const clinicHasCustomConfiguration = (locationId: string, property: string) =>
  !isEmptyObject(CLINIC_CONFIG[locationId]) && !isEmptyObject(CLINIC_CONFIG[locationId][property]);

const getClinicConfigAttribute = (locationId: string, property: string) => {
  if (!clinicHasCustomConfiguration(locationId, property)) {
    return null;
  }

  return CLINIC_CONFIG[locationId][property];
};

const URGENT_CARE_FOR_KIDS_CEDAR_PARK_LOCATION_ID = 'vp3okp';

const PEDIATRIC_URGENT_CARE_FORT_WORTH_VIDEO_VISIT_LOCATION_ID = 'A9RBlp';

const URGENT_CARE_FOR_KIDS_TELEMED = 'AWMwyg';
const LIBERTY_URGENT_CARE_TELEMED = 'A639Lp';
const WESTEX_TELEMED = 'gKDn60';
const WESTMED_TELEMED = 'gqD7np';

const CYPRESS_TESTING_LOCATION_ID = '0mDX9g';

const MULTICARE_LAKEWOOD_LOCATION_ID = 'AWE2vg';
const MULTICARE_UNIVERSITY_PLACE_LOCATION_ID = '0ry8Og';
const MULTICARE_GIG_HARBOR_LOCATION_ID = '0xjnop';
const MULTICARE_SPANAWAY_LOCATION_ID = 'A9QVlA';
const MULTICARE_PUYALLUP = 'AzVnNA';
const MULTICARE_AUBURN_LOCATION_ID = 'g1PNqp';
const MULTICARE_WEST_TACOMA_LOCATION_ID = 'gJVKzg';
const MULTICARE_AUBURN_LAKELAND_COMMONS_LOCATION_ID = 'g1m8Zg';
const MULTICARE_FEDERAL_WAY_LOCATION_ID = 'AXXX2A';
const MULTICARE_POINT_RUSTON_LOCATION_ID = 'g544Kp';
const NEXT_CARE_ARIZONA_VIRTUAL_VISIT_LOCATION_ID = 'p8RYD0';
const NEXT_CARE_CEDAR_PARK_LOCATION_ID = 'g5dLop';
const NEXT_CARE_WILLIAM_CANNON_LOCATION_ID = 'gLqoG0';
const NEXT_CARE_ROUND_ROCK_LOCATION_ID = 'oAX67g';
const NEXT_CARE_GOERGETOWN_LOCATION_ID = '0VO4MA';
const NEXT_CARE_MARBEL_FALLS_LOCATION_ID = 'OABJNA';

const MULTICARE_INTEGRATION_PHASE_TWO_LOCATIONS = [
  MULTICARE_AUBURN_LAKELAND_COMMONS_LOCATION_ID,
  MULTICARE_FEDERAL_WAY_LOCATION_ID,
  MULTICARE_POINT_RUSTON_LOCATION_ID,
  'gQo92A',
  'AaDKX0',
  'pDYV7g',
  'goyLMA',
  'AvZZ8A',
  'go82k0',
  'py3B60',
  'pjwKGg',
  'gJMjzp',
  'gd81wg',
  'p8OY8A',
  '0eRPOA',
  'gZ58rp',
  'p8RdO0',
  '0x8M60',
  'A2mk80',
  'A4EOwp',
  'A6RLMA',
  '0xvyrp',
  'AMZzNp',
  'A2w95p',
  'AXMQ7p',
  'AzQRM0',
  'A6yJ30',
  'gqoEEA',
  'pn88Q0',
  'pRL6z0',
  '0m8Qzg',
  'gNeeLp',
  'A4ml2p',
  '0k8znA',
  'A2m750',
  'gQRRe0',
  'A6R961',
  'AWmGyg',
  'gLmqG0',
  'g1P1oZ',
  '0xjXKj',
  'pYOn1l',
  '0ry2Kv',
  'A246b5',
  'AWEnBG',
  'gbkn23',
  'A9QrjB',
  'pnQN7m',
  'A6RzY2',
  'gZen68',
  'gNVnkJ',
  'AaRnr8',
  'AzVLKM',
  'g1P19W',
];

const FREE_CHAT_ENABLED_LOCATIONS = [
  NEXT_CARE_ARIZONA_VIRTUAL_VISIT_LOCATION_ID,
  NEXT_CARE_CEDAR_PARK_LOCATION_ID,
  NEXT_CARE_WILLIAM_CANNON_LOCATION_ID,
  NEXT_CARE_ROUND_ROCK_LOCATION_ID,
  NEXT_CARE_GOERGETOWN_LOCATION_ID,
  NEXT_CARE_MARBEL_FALLS_LOCATION_ID,
];

const JORDANS_DEMO_LOCATION_ID = '0xxLb0';

const COVID_19_VAX_FORM_LOCATIONS = [
  JORDANS_DEMO_LOCATION_ID,
  '0xwL6g', // International Community Health Services (ICHS) | Pop-Up Community Vaccination Site
  '0OKRY0', // HealthPoint Vaccination | Federal Way
  '0VJOn0', // HealthPoint Vaccination | Auburn
  'AMJVvp', // HealthPoint Vaccination | Redmond
  'gdz8mA', // HealthPoint Vaccination | Tukwila
  'gJXMoA', // HealthPoint Vaccination | Renton
  'gqwo9A', // HealthPoint Vaccination | SeaTac
  'gwwLbg', // HealthPoint Vaccination | Bothell
  'p3Vo2A', // HealthPoint Vaccination | Midway
  'plEb4g', // HealthPoint Vaccination | Kent
  '0kVLWn', // Snohomish Health District Vaccines | Ash Way Park and Ride
  '0xjeVj', // Snohomish Health District Vaccines | Mobile Site: Special Populations Clinic
  'A6RWo2', // Snohomish Health District Vaccines | Mobile Site: Community Clinic
  'gQGwMe', // Snohomish Health District Vaccines | Boom City
  'gZeqK8', // Snohomish Health District Vaccines | Testing 3
  '0mE5ER', // Snohomish Health District Vaccines | Pediatric Vaccine Clinic (5-11 y/o) 4
  '0xjXKo', // Snohomish Health District Vaccines | Community Vaccine Clinic (12+ Y/O) 1
  'AGLbLJ', // Snohomish Health District Vaccines | Community Vaccine Clinic (12+ Y/O) 6
  'gd8b8b', // Snohomish Health District Vaccines | Community Vaccine Clinic (12+ Y/O) 3
  'gKvYvO', // Snohomish Health District Vaccines | Community Vaccine Clinic (12+ Y/O) 4
  'gqoVor', // Snohomish Health District Vaccines | Community Vaccine Clinic (12+ Y/O) 2
  'pRGnkD', // Snohomish Health District Vaccines | Pediatric Vaccine Clinic (5-11 y/o) 3
  'pRGPlD', // Snohomish Health District Vaccines | Special Populations Mobile Vaccine Clinic
  'pyXqXw', // Snohomish Health District Vaccines | Community Vaccine Clinic (12+ Y/O) 5
];

export {
  clinicHasCustomConfiguration,
  getClinicConfigAttribute,
  CLINIC_CONFIG,
  REVIEW_DEFAULT_MINIMUM_RATING,
  UNIVERSITY_URGENT_CARE_LOCATION_ID,
  SMART_CARE_LOCATION_IDS,
  SMART_CARE_ANAHEIM,
  SMART_CARE_BOSTON,
  SMART_CARE_DALLAS,
  SMART_CARE_SF,
  SMART_CARE_VIRTUAL_VISIT,
  SMART_CARE_NEW_YORK,
  URGENT_CARE_FOR_KIDS_CEDAR_PARK_LOCATION_ID,
  URGENT_CARE_FOR_KIDS_TELEMED,
  PEDIATRIC_URGENT_CARE_FORT_WORTH_VIDEO_VISIT_LOCATION_ID,
  LIBERTY_URGENT_CARE_TELEMED,
  WESTEX_TELEMED,
  WESTMED_TELEMED,
  CYPRESS_TESTING_LOCATION_ID,
  MULTICARE_LAKEWOOD_LOCATION_ID,
  MULTICARE_UNIVERSITY_PLACE_LOCATION_ID,
  MULTICARE_GIG_HARBOR_LOCATION_ID,
  MULTICARE_SPANAWAY_LOCATION_ID,
  MULTICARE_PUYALLUP,
  MULTICARE_AUBURN_LOCATION_ID,
  MULTICARE_WEST_TACOMA_LOCATION_ID,
  FREE_CHAT_ENABLED_LOCATIONS,
  JORDANS_DEMO_LOCATION_ID,
  COVID_19_VAX_FORM_LOCATIONS,
  MULTICARE_INTEGRATION_PHASE_TWO_LOCATIONS,
};
