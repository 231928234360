import { isSolvEmployee, getGroupsIds } from '../session';
import { isEmptyObject, safeGet } from '../util/object';
import moment from 'moment-timezone';
import { MONTH_DAY_YEAR, HOUR_MINUTE_PERIOD_NO_SPACE } from '../../components/DateTime';
import { getUserAgent } from '../util/device';
import { isEmptyArray } from '../util/array';
import { includesTelemedLocation } from '../util/location';
import { GenericObject, PropType } from '../util/generics';
import { Location, LocationUnformatted, RootState } from '../../types/RootState';

type Req = {
  login: PropType<RootState, 'login'>;
  location?: PropType<RootState, 'location'>;
  locations?: Array<Location & LocationUnformatted>;
};

const getTrackingProperties = ({ login, location = {}, locations = [] }: Req) => {
  const trackingProperties: GenericObject = { user_agent: getUserAgent() };

  if (!isEmptyObject(login ?? {})) {
    trackingProperties.clinic_account_id = safeGet(login.account)('id');
    trackingProperties.is_solv_employee = isSolvEmployee(login);
    trackingProperties.role = safeGet(login.account)('roles').join(', ');
    trackingProperties.group_id = getGroupsIds(login);
  }

  if (!isEmptyObject(location ?? {})) {
    trackingProperties.location_id = location.id;
    trackingProperties.group_id =
      location?.groups?.length > 0 ? safeGet(location?.groups[0])('group_id') : '';
    trackingProperties.timestamp_date = String(
      moment.tz(safeGet(location)('time_zone')).format(MONTH_DAY_YEAR)
    );
    trackingProperties.timestamp_time = String(
      moment.tz(safeGet(location)('time_zone')).format(HOUR_MINUTE_PERIOD_NO_SPACE)
    );
    trackingProperties.platform_type = location.platform_type || location.platformType;
    trackingProperties.isTelemed = Boolean(location.isTelemed || location.is_telemed);
  }

  if (!isEmptyArray(locations)) {
    // retain original data types from pre-MQV for mixpanel tracking continuity
    trackingProperties.location_id = locations.map((l) => l.id).join(',');
    trackingProperties.group_id = locations
      .filter((l) => l.groups?.length > 0)
      .map((l) => safeGet(l.groups[0])('group_id'))
      .join(',');
    trackingProperties.timestamp_date = locations
      .map((l) => String(moment.tz(safeGet(l)('timeZone')).format(MONTH_DAY_YEAR)))
      .join(',');
    trackingProperties.timestamp_time = locations
      .map((l) => String(moment.tz(safeGet(l)('timeZone')).format(HOUR_MINUTE_PERIOD_NO_SPACE)))
      .join(',');
    trackingProperties.platform_type = locations.map((l) => l.platformType).join(',');
    trackingProperties.isTelemed = includesTelemedLocation(locations);

    // maybe eventually we'll only need to track these new MQV properties
    trackingProperties.location_ids = locations.map((l) => l.id);
    trackingProperties.group_ids = locations
      .filter((l) => l.groups?.length > 0)
      .map((l) => safeGet(l.groups[0])('group_id'));
    trackingProperties.timestamp_dates = locations.map((l) =>
      String(moment.tz(safeGet(l)('timeZone')).format(MONTH_DAY_YEAR))
    );
    trackingProperties.timestamp_times = locations.map((l) =>
      String(moment.tz(safeGet(l)('timeZone')).format(HOUR_MINUTE_PERIOD_NO_SPACE))
    );
    trackingProperties.platform_types = locations.map((l) => l.platformType);
    trackingProperties.includes_telemed_location = includesTelemedLocation(locations);
    trackingProperties.num_locations = locations.length;
  }

  return trackingProperties;
};

export { getTrackingProperties };
