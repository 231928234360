import { AccountLogin } from '../../types/RootState';
import { getBooleanFlag } from '../flags/flags';
import {
  isAdmin,
  isManager,
  isProvider,
  isBackOfficeUser,
  isCitydocUser,
  isFrontOfficeUser,
  isItAdmin,
  isNextCareUser,
  isCallCenterRep,
  hasAccessToLocationWithPayments,
  hasAccessToLocationWithConsent,
  isSolvEmployee,
  hasAccessToLocationWithPosEnabled,
  isAllianceUser,
  hasAccessToTelemedLocation,
  hasAccessToLocationWithPaperlessHandoff,
  hasAccessToLocationWithVideoVisitHandoff,
  hasAccessToLocationWithPaperwork,
  hasAccessToLocationWithKioskCustomQuestions,
  isNextCareCallCenter,
  isLabResultsAccessEnabled,
  isProviderGroupUser,
  hasAccessToDrChronoLocation,
  isNormalManager,
  isCallCenterManager,
} from '../session';

const showCallCenterTab = (login: AccountLogin) =>
  isCallCenterRep(login) || isCallCenterManager(login) || isSolvEmployee(login);

const showCapacityTab = (login: AccountLogin) =>
  isNextCareUser(login) || isSolvEmployee(login) || isAdmin(login);

const showChangeLocationDropdown = (login: AccountLogin) =>
  isFrontOfficeUser(login) ||
  isBackOfficeUser(login) ||
  isManager(login) ||
  isProvider(login) ||
  isSolvEmployee(login) ||
  isAdmin(login);

const showDisableReservations = (login: AccountLogin) =>
  !(isNextCareUser(login) && (isManager(login) || isProvider(login)));

const showEmergent = (login: AccountLogin) => isNextCareUser(login) && !isCallCenterRep(login);

const showFacesheetModalBookingDetailsTab = (login: AccountLogin) =>
  isSolvEmployee(login) || isAdmin(login);

const showFacesheetModalBookingHistoryTab = (login: AccountLogin) =>
  isSolvEmployee(login) || isAdmin(login) || isManager(login);

const showRaceAndEthnicityFields = (login: AccountLogin) => isCitydocUser(login);

const showFacesheetModalConsentFormsTab = (login: AccountLogin) =>
  hasAccessToLocationWithConsent(login);

const showFacesheetModalLabResultsTab = (login: AccountLogin) => isLabResultsAccessEnabled(login);

const showFacesheetModalVisitSummaryTab = (login: AccountLogin) =>
  isProvider(login) || isSolvEmployee(login) || isManager(login);

const showHelloReportsButton = (login: AccountLogin) =>
  isManager(login) ||
  isSolvEmployee(login) ||
  isAdmin(login) ||
  isNextCareCallCenter(login) ||
  isProvider(login);

const showLocationSettingsIntegrations = isSolvEmployee;

const showLocationSettingsGeneralInformation = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  !(isNextCareUser(login) && (isManager(login) || isProvider(login)));

const showLocationSettingsHoursReservationCapacity = (login: AccountLogin) =>
  !(isNextCareUser(login) && (isManager(login) || isProvider(login)));

const showLocationSettingsHoursAndScheduling = (login: AccountLogin) => !isProviderGroupUser(login);

const showLocationSettingsInsurance = (login: AccountLogin) =>
  isNextCareCallCenter(login) || isManager(login) || isSolvEmployee(login);

const showLocationSettingsInternalAdmin = (login: AccountLogin) => isSolvEmployee(login);

const showLocationSettingsPaperwork = (login: AccountLogin) =>
  !isProviderGroupUser(login) && (isSolvEmployee(login) || isManager(login) || isProvider(login));

const showLocationSettingsServices = (login: AccountLogin) => !isProviderGroupUser(login);

const showLocationSettingsSingleUseLinks = (login: AccountLogin) =>
  isManager(login) || isAdmin(login) || isSolvEmployee(login);

const showLocationSettingsConsentFormUpload = (login: AccountLogin) => isSolvEmployee(login);

const showLocationSettingsLabResults = (login: AccountLogin) => isSolvEmployee(login);

const showPaperworkResponsesTab = (login: AccountLogin) =>
  hasAccessToLocationWithPaperwork(login) || hasAccessToLocationWithKioskCustomQuestions(login);

const showSettingsTabUsers = (login: AccountLogin) =>
  isItAdmin(login) ||
  isSolvEmployee(login) ||
  isAdmin(login) ||
  (!isNextCareUser(login) && !isProviderGroupUser(login) && isManager(login));

const showSettingsTabUsersServerSide = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  (isItAdmin(login) ||
    isSolvEmployee(login) ||
    isAdmin(login) ||
    isManager(login) ||
    isProvider(login));

const showSettingsTabGroups = (login: AccountLogin) => isSolvEmployee(login) || isAdmin(login);

const showSettingsTabProviders = (login: AccountLogin) => isSolvEmployee(login) || isAdmin(login);

const showSettingsTabPractices = (login: AccountLogin) => isSolvEmployee(login) || isAdmin(login);

const showPatientsReport = (login: AccountLogin) => isSolvEmployee(login) || isAdmin(login);

const showPatientsAccountsTab = (login: AccountLogin) => isSolvEmployee(login) || isAdmin(login);

const showReviewsReportsTab = (login: AccountLogin) =>
  isManager(login) ||
  isSolvEmployee(login) ||
  isAdmin(login) ||
  isNextCareCallCenter(login) ||
  isProvider(login);

const showPatientBoostReportsTab = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  (isManager(login) ||
    isSolvEmployee(login) ||
    isAdmin(login) ||
    isNextCareCallCenter(login) ||
    isProvider(login));

const showPatientExperienceReportsTab = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  (isManager(login) ||
    isSolvEmployee(login) ||
    isAdmin(login) ||
    isNextCareCallCenter(login) ||
    isProvider(login));

const showPaperlessReportsTab = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  (isManager(login) ||
    isSolvEmployee(login) ||
    isAdmin(login) ||
    isNextCareCallCenter(login) ||
    isProvider(login));

const showTelemedReportsTab = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  (isManager(login) ||
    isSolvEmployee(login) ||
    isAdmin(login) ||
    isNextCareCallCenter(login) ||
    isProvider(login));

const showReportsTab = (login: AccountLogin) =>
  isFrontOfficeUser(login) ||
  isBackOfficeUser(login) ||
  isManager(login) ||
  isSolvEmployee(login) ||
  isAdmin(login) ||
  isProvider(login) ||
  isNextCareCallCenter(login);

const showSettingsTabCustomQuestions = (login: AccountLogin) =>
  isSolvEmployee(login) || isAdmin(login);

const showSettingsTabLocations = (login: AccountLogin) => {
  if (isSolvEmployee(login) || isAdmin(login)) {
    return true;
  }

  if (isProviderGroupUser(login)) {
    return hasAccessToDrChronoLocation(login);
  }

  return (
    isItAdmin(login) || isManager(login) || isSolvEmployee(login) || isNextCareCallCenter(login)
  );
};

const showPatientsTab = (login: AccountLogin) => !isProviderGroupUser(login);

const showNextCareMissingLocationText = (login: AccountLogin) =>
  isNextCareUser(login) && !isCallCenterRep(login);

const showQueueTab = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  (isFrontOfficeUser(login) ||
    isBackOfficeUser(login) ||
    isNormalManager(login) ||
    isSolvEmployee(login) ||
    isProvider(login) ||
    isAdmin(login));

const showHelpCenter = (login: AccountLogin) => !isProviderGroupUser(login);

const showReadyTab = (login: AccountLogin) => isNextCareUser(login);

const showTelemedFlag = (login: AccountLogin) =>
  hasAccessToTelemedLocation(login) && !isNextCareUser(login);

const showTriaged = (login: AccountLogin) => isNextCareUser(login);

const showPatientsLabResultsTab = (login: AccountLogin) => isLabResultsAccessEnabled(login);

const showPayments = (login: AccountLogin) => {
  if (isProviderGroupUser(login)) return false;

  return hasAccessToLocationWithPayments(login) || isSolvEmployee(login) || isAdmin(login);
};

const showPaymentsNonSolv = (login: AccountLogin) => isAdmin(login) || isSolvEmployee(login);

const showPaymentsPOS = (login: AccountLogin) =>
  hasAccessToLocationWithPosEnabled(login) || isSolvEmployee(login) || isAdmin(login);

const showPaymentsSettings = (login: AccountLogin) => isAdmin(login) || isSolvEmployee(login);

const showLiveStats = (login: AccountLogin) =>
  !isProviderGroupUser(login) &&
  (isManager(login) ||
    isProvider(login) ||
    isSolvEmployee(login) ||
    isAdmin(login) ||
    isNextCareCallCenter(login));

const showReservationHoursTab = (login: AccountLogin) =>
  isItAdmin(login) ||
  isManager(login) ||
  isProvider(login) ||
  isSolvEmployee(login) ||
  isAdmin(login);

const showReviewsModerationTab = (login: AccountLogin) => isSolvEmployee(login);

const showChat = (login: AccountLogin) => !isAllianceUser(login);

const showPlatformUsageAlerts = (login: AccountLogin) => false;

const showPaperlessHandoff = (login: AccountLogin) =>
  hasAccessToLocationWithPaperlessHandoff(login);

const showVideoVisitHandoff = (login: AccountLogin) =>
  hasAccessToLocationWithVideoVisitHandoff(login);

const showClinicAccountsGroupsEditor = (login: AccountLogin) => isSolvEmployee(login);

const showPatientCommunications = (login: AccountLogin) => isSolvEmployee(login);

const showProvidersInLocationSelector = (login: AccountLogin) => isProviderGroupUser(login);

const showCustomQuickReplies = (login: AccountLogin) =>
  isSolvEmployee(login) ||
  (isManager(login) && getBooleanFlag('grant-manager-role-access-to-custom-quick-replies'));

export {
  showCallCenterTab,
  showCapacityTab,
  showChangeLocationDropdown,
  showClinicAccountsGroupsEditor,
  showDisableReservations,
  showEmergent,
  showFacesheetModalBookingDetailsTab,
  showFacesheetModalBookingHistoryTab,
  showFacesheetModalConsentFormsTab,
  showFacesheetModalLabResultsTab,
  showFacesheetModalVisitSummaryTab,
  showLocationSettingsIntegrations,
  showLocationSettingsGeneralInformation,
  showLocationSettingsHoursReservationCapacity,
  showLocationSettingsHoursAndScheduling,
  showLocationSettingsInsurance,
  showLocationSettingsInternalAdmin,
  showLocationSettingsPaperwork,
  showLocationSettingsSingleUseLinks,
  showLocationSettingsServices,
  showLocationSettingsConsentFormUpload,
  showRaceAndEthnicityFields,
  showHelloReportsButton,
  showPatientsReport,
  showPatientsAccountsTab,
  showSettingsTabUsers,
  showSettingsTabUsersServerSide,
  showSettingsTabCustomQuestions,
  showSettingsTabLocations,
  showSettingsTabGroups,
  showLocationSettingsLabResults,
  showNextCareMissingLocationText,
  showQueueTab,
  showPaperworkResponsesTab,
  showPayments,
  showPaymentsPOS,
  showPaymentsSettings,
  showReadyTab,
  showReviewsReportsTab,
  showPaymentsNonSolv,
  showReportsTab,
  showReservationHoursTab,
  showLiveStats,
  showTelemedFlag,
  showTriaged,
  showPatientsLabResultsTab,
  showChat,
  showReviewsModerationTab,
  showPlatformUsageAlerts,
  showPaperlessHandoff,
  showVideoVisitHandoff,
  showPatientBoostReportsTab,
  showPatientExperienceReportsTab,
  showPaperlessReportsTab,
  showTelemedReportsTab,
  showSettingsTabProviders,
  showSettingsTabPractices,
  showPatientsTab,
  showHelpCenter,
  showProvidersInLocationSelector,
  showPatientCommunications,
  showCustomQuickReplies,
};
