import { call, put } from 'redux-saga/effects';
import {
  getEligibilityByUserProfileIdAndBookingId,
  getEligibilityByInsuranceProfileIdAndBookingId,
  getInsuranceDetailsUrl,
} from '../../../../core/dapi/eligibilityCheck';
import {
  eligibilityRequestIdReceived,
  paymentsInsuranceReceived,
} from '../../../../actions/currentPatient';
import { setRuntimeVariable } from '../../../../actions/runtime';
import { isEmptyArray } from '../../../../core/util/array';
import { apiGet, apiPost, apiPatch, apiDeleteJson } from '../../../../core/dapi/index';
import { insurersError, insurersReceived } from '../../../../actions/insurers';
import { getFullInsurersListUrl } from '../../../../core/dapi/insurers';
import { createInsuranceProfileUrl } from '../../../../core/dapi/insuranceProfiles';
import { getBookingByIdUrl } from '../../../../core/dapi/bookings';
import { getBookingSuccess } from '../../../../actions/queue';
import {
  checkInsuranceCoverageUrl,
  setBookingInsuranceUrl,
} from '../../../../core/dapi/insuranceCoverage';
import { getChargesByBookingUrl, getChargesPOSUrl } from '../../../../core/dapi/charges';
import { getPaymentCardUrl, deletePaymentCardUrl } from '../../../../core/dapi/invoices';
import { chargesReceived, chargesError, chargesSubmitting } from '../../../../actions/charge';
import { cardsError, cardsReceived } from '../../../../ducks/paymentCustomers';

export const FETCHING_ELIGIBILITY = 'fetchingEligibility';
export const FETCHING_PAYMENT_CARDS = 'fetchingPaymentCards';
export const FETCHING_PAYMENT_CHARGE = 'fetchingPaymentCharge';
export const DELETING_PAYMENT_CARD = 'deletingPaymentCard';

export function* submitInsuranceForm(postAndBookingData) {
  try {
    const { postData, booking } = postAndBookingData;
    const insuranceProfileUrl = createInsuranceProfileUrl();
    const bookingUrl = getBookingByIdUrl(booking.id);
    const insuranceCoverageUrl = checkInsuranceCoverageUrl();
    const bookingInsuranceUrl = setBookingInsuranceUrl();

    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: true }));

    const createInsuranceProfileResponse = yield call(apiPost, insuranceProfileUrl, {
      ...postData,
      insurer_type: postData.plan_type,
    });

    const patchBooking = yield call(apiPatch, bookingUrl, {
      insurance_profile_id: createInsuranceProfileResponse.id,
    });

    yield put(getBookingSuccess(patchBooking));

    const postInsuranceCoverageParams = {
      ...postData,
      insurance_profile_id: createInsuranceProfileResponse.id,
    };

    const insuranceCoverageResult = yield call(
      apiPost,
      insuranceCoverageUrl,
      postInsuranceCoverageParams
    );

    yield put(eligibilityRequestIdReceived(insuranceCoverageResult.eligibility_requests_id));

    const insuranceCoverageParams = {
      eligibility_request_id: insuranceCoverageResult.eligibility_requests_id,
      booking_id: booking.id,
    };

    yield call(apiPost, bookingInsuranceUrl, insuranceCoverageParams);

    const eligibilityByInsuranceProfileIdAndBookingIdUrl = getEligibilityByInsuranceProfileIdAndBookingId(
      createInsuranceProfileResponse.id,
      booking.id
    );

    const response = yield call(apiGet, eligibilityByInsuranceProfileIdAndBookingIdUrl);

    const insuranceDetailsUrl = getInsuranceDetailsUrl(
      response.results[0].id,
      createInsuranceProfileResponse.id,
      'UC'
    );

    const detailsResponse = yield call(apiGet, insuranceDetailsUrl);
    yield put(paymentsInsuranceReceived(detailsResponse));
    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  } catch (e) {
    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  }
}

export function* fetchInsurersList() {
  try {
    const insurersListUrl = getFullInsurersListUrl();

    const response = yield call(apiGet, insurersListUrl);
    yield put(insurersReceived(response));
  } catch (e) {
    yield put(insurersError(e));
  }
}

export function* fetchInsuranceSummary({
  insuranceProfileId,
  userProfileId,
  bookingId,
  serviceCode,
}) {
  try {
    const userProfileIdAndBookingIdUrl = getEligibilityByUserProfileIdAndBookingId(
      userProfileId,
      bookingId
    );
    const insuranceProfileAndBookingIdUrl = getEligibilityByInsuranceProfileIdAndBookingId(
      insuranceProfileId,
      bookingId
    );

    let response;

    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: true }));

    if (!!insuranceProfileId) {
      response = yield call(apiGet, insuranceProfileAndBookingIdUrl);
      if (!isEmptyArray(response.results)) {
        yield put(eligibilityRequestIdReceived(response.results[0].id));
      }

      if (isEmptyArray(response.results)) {
        response = yield call(apiGet, userProfileIdAndBookingIdUrl);
        if (!isEmptyArray(response.results)) {
          yield put(eligibilityRequestIdReceived(response.results[0].id));
        }
      }
    }

    if (!response || isEmptyArray(response.results)) {
      response = yield call(apiGet, userProfileIdAndBookingIdUrl);
      if (!isEmptyArray(response.results)) {
        yield put(eligibilityRequestIdReceived(response.results[0].id));
      }
    }

    if (!isEmptyArray(response.results)) {
      const insuranceDetailsUrl = getInsuranceDetailsUrl(
        response.results[0].id,
        insuranceProfileId,
        serviceCode || 'UC'
      );

      response = yield call(apiGet, insuranceDetailsUrl);
      yield put(paymentsInsuranceReceived(response));
    }

    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  } catch (e) {
    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  }
}

export function* fetchCharge({ bookingId }) {
  try {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CHARGE, value: true }));
    const response = yield call(apiGet, getChargesByBookingUrl(bookingId));
    if (response && response.results && response.results.length > 0) {
      yield put(chargesReceived(response.results[0]));
    } else {
      yield put(chargesReceived(null));
    }
  } catch (e) {
    yield put(cardsError(e));
  } finally {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CHARGE, value: false }));
  }
}

export function* fetchCards({ accountId }) {
  try {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CARDS, value: true }));
    const response = yield call(apiGet, getPaymentCardUrl(accountId));
    if (response && response.results && response.results.length > 0) {
      yield put(cardsReceived(response.results));
    } else {
      yield put(cardsReceived(null));
    }
  } catch (e) {
    yield put(cardsError(e));
  } finally {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CARDS, value: false }));
  }
}

export function* postCharge({ token, savedCard, booking, amount, reason }) {
  try {
    yield put(chargesSubmitting());
    const chargePostData = {
      token,
      saved_card: savedCard,
      booking_id: booking.id,
      amount,
      reason,
    };

    const response = yield call(apiPost, getChargesPOSUrl(), chargePostData);
    yield put(chargesReceived(response));
  } catch (e) {
    yield put(chargesError(e));
  }
}

export function* deleteCard({ accountId, cardId, vendorId }) {
  try {
    yield put(setRuntimeVariable({ name: DELETING_PAYMENT_CARD, value: true }));
    const deleteCardData = {
      card_id: cardId,
      vendor_id: vendorId,
    };
    const response = yield call(apiDeleteJson, deletePaymentCardUrl(accountId), deleteCardData);
    if (response && response?.data?.deleted) {
      yield put(cardsReceived(null));
    }
  } catch (e) {
    yield put(cardsError(e));
  } finally {
    yield put(setRuntimeVariable({ name: DELETING_PAYMENT_CARD, value: false }));
  }
}

export function* setChargeSubmitting() {
  yield put(chargesSubmitting());
}

export function* setChargeError({ error }) {
  yield put(chargesError(error || 'An error occurred.'));
}
