import {
  ENV_DEV,
  ENV_STAGE,
  ENV_TEST,
  ENV_PROD,
  ENV_SECURE_PROD,
  ENV_LIVESTAGE,
} from '../constants';

import { log, LOG_LEVEL_DEBUG, LOG_LEVEL_ERROR } from '../core/logger/log';
import { thisYear } from '../core/util/date';
import { getAllClientConfig, getClientConfig } from '../core/config/nextConfig';

const config = getAllClientConfig();

export let SOLV_ENV = config.NEXT_PUBLIC_SOLV_ENV;

if (!SOLV_ENV) {
  SOLV_ENV = ENV_DEV;
}

export const PORT = config.PORT || 5100;

let dApiHost = null;
let dApiCactusHost = null;
let dApiCloudfrontHost = null;
let dApiAuthEnabled = false;
let mApiToken = null;
let mAppHost = null;
let consumerAppHost = null;
let segmentApiKey = null;
let mInspectletEnabled = false;
let mAnalyticsEnabled = false;
let mApiClientId = null;
let mApiClientSecret = null;
let mIntercomEnabled = true;
let stripeApiKey = 'pk_test_e86KtpsFhEw4rOxoEuRAy2A3';
let stripeClientId = 'ca_Br2UXM9krEiol7NY30fPp91b8G0knv02';
let tokboxApiKey = null;
let acuantApiKey = null;
let postVisitSummaryEntryEnabled = false;

log.setLevel(LOG_LEVEL_DEBUG);

switch (SOLV_ENV) {
  case ENV_SECURE_PROD:
  case ENV_PROD:
  case ENV_LIVESTAGE:
    log.setLevel(LOG_LEVEL_ERROR);
    segmentApiKey = 'b8gGF9cYxa5rnP7IncdE5MsnK66vaqZt';
    mAnalyticsEnabled = true;
    mInspectletEnabled = true;
    dApiHost = 'https://secure-dapi.herokuapp.com';
    mAppHost = 'https://facility-manage.herokuapp.com';
    consumerAppHost = 'https://www.solvhealth.com';
    mApiClientId = '75e552fec6424520a304f5c5a9e5f51c';
    mApiClientSecret = 'a8a0473043344e43bcb027f1eb7b52ca';
    mApiToken = '90dd1fcea0074e7eb4b11e3753a0a334';
    dApiAuthEnabled = true;
    mIntercomEnabled = true;
    break;
  case ENV_STAGE:
  case ENV_TEST:
    dApiHost = 'https://secure-dapi-qa.herokuapp.com';
    mAppHost = 'facility-manage-stage.herokuapp.com';
    mApiClientId = '75e552fec6424520a304f5c5a9e5f51c';
    mApiClientSecret = 'a8a0473043344e43bcb027f1eb7b52ca';
    segmentApiKey = 'b8gGF9cYxa5rnP7IncdE5MsnK66vaqZt';
    dApiAuthEnabled = true;
    log.setLevel(LOG_LEVEL_DEBUG);
    mApiClientId = '75e552fec6424520a304f5c5a9e5f51c';
    mApiClientSecret = 'a8a0473043344e43bcb027f1eb7b52ca';
    mAppHost = `localhost:${PORT}`;
    consumerAppHost = 'http://localhost:5000';
    segmentApiKey = 'b8gGF9cYxa5rnP7IncdE5MsnK66vaqZt';
    mApiToken = '90dd1fcea0074e7eb4b11e3753a0a334';
    mIntercomEnabled = false;
    break;
  case ENV_DEV:
  default:
    dApiAuthEnabled = true;
    segmentApiKey = null;
    dApiHost = 'http://localhost:8000';
    mAppHost = `localhost:${PORT}`;
    consumerAppHost = 'http://localhost:5000';
    segmentApiKey = 'b8gGF9cYxa5rnP7IncdE5MsnK66vaqZt';
    mApiClientId = '75e552fec6424520a304f5c5a9e5f51c';
    mApiClientSecret = 'a8a0473043344e43bcb027f1eb7b52ca';
    mApiToken = '90dd1fcea0074e7eb4b11e3753a0a334';
    dApiAuthEnabled = true;
    log.setLevel(LOG_LEVEL_DEBUG);
    stripeClientId = 'ca_Br2UzSGETqgJRMQJjsqjaNpMrINM60S8';
    mIntercomEnabled = false;
    postVisitSummaryEntryEnabled = true;
    break;
}

export const ANALYTICS = {
  enabled: mAnalyticsEnabled,
  segment: { apiKey: segmentApiKey },
  integrations: {
    All: false,
    'Google Analytics': true,
    Mixpanel: true,
  },
  inpsectletPaths: [/^\/welcome\//],
};

export const INSPECTLET_ENABLED = mInspectletEnabled;

if (config.NEXT_PUBLIC_DAPI_HOST) {
  dApiHost = config.NEXT_PUBLIC_DAPI_HOST;
}

if (config.NEXT_PUBLIC_DAPI_CACTUS_HOST) {
  dApiCactusHost = config.NEXT_PUBLIC_DAPI_CACTUS_HOST;
}

if (config.NEXT_PUBLIC_TOKBOX_API_KEY) {
  tokboxApiKey = config.NEXT_PUBLIC_TOKBOX_API_KEY;
}

if (config.NEXT_PUBLIC_ACUANT_API_KEY) {
  acuantApiKey = config.NEXT_PUBLIC_ACUANT_API_KEY;
}

if (config.NEXT_PUBLIC_MAPP_HOST) {
  consumerAppHost = config.NEXT_PUBLIC_MAPP_HOST;
}

if (config.NEXT_PUBLIC_MANAGE_HOST) {
  mAppHost = config.NEXT_PUBLIC_MANAGE_HOST;
}

if (config.NEXT_PUBLIC_DAPI_CLOUDFRONT_HOST) {
  dApiCloudfrontHost = config.NEXT_PUBLIC_DAPI_CLOUDFRONT_HOST;
}

if (config.NEXT_PUBLIC_POST_VISIT_SUMMARY_ENTRY_ENABLED) {
  postVisitSummaryEntryEnabled = config.NEXT_PUBLIC_POST_VISIT_SUMMARY_ENTRY_ENABLED;
}

export let DAPI_HOST = dApiHost;
export let DAPI_CACTUS_HOST = dApiCactusHost;

export let DAPI_FAST_API_HOST = config.NEXT_PUBLIC_FAST_API_HOST || DAPI_HOST;
export let DAPI_CLOUDFRONT_HOST = dApiCloudfrontHost || dApiHost;

export function enableFastApi() {
  console.debug('enabling fast api', DAPI_FAST_API_HOST);
  DAPI_HOST = DAPI_FAST_API_HOST;
  DAPI_CACTUS_HOST = DAPI_FAST_API_HOST;
  DAPI_CLOUDFRONT_HOST = DAPI_FAST_API_HOST;
}

export const DAPI_AUTH_ENABLED = dApiAuthEnabled;
export const DAPI_TOKEN = mApiToken;
export const DAPI_CLIENT_ID = mApiClientId;
export const DAPI_CLIENT_SECRET = mApiClientSecret;
export const MANAGE_HOST = mAppHost;
export const MAPP_HOST = consumerAppHost;
export const TOKBOX_API_KEY = tokboxApiKey;
export const ACUANT_API_KEY = acuantApiKey;
export const POST_VISIT_SUMMARY_ENTRY_ENABLED = postVisitSummaryEntryEnabled;

export const REFRESH_TOKEN_TIMEOUT = 13 * 60 * 1000;

export const ONBOARDED_COOKIE_NAME = 'onboarded';
export const RETURNING_USER_COOKIE_NAME = 'solv';
export const LOGIN_TOKEN_NAME = 'login';
export const WELCOME_PAGE_COOKIE_NAME = 'welcome_page_path';
export const HIDE_CHANGE_PHONE_NUMBER_COOKIE_NAME = 'hideChangePhoneNumber';
export const LONG_TERM_SESSION_ID_COOKIE_NAME = 'ltsid';
export const ADD_TO_HOME_SCREEN_POP_UP_COOKIE_NAME = 'hide-add-to-home-screen';
export const LAST_REVIEW_SEEN_COOKIE_NAME = 'last_review_seen';
export const LAST_BOOST_PATIENT_SEEN_COOKIE_NAME = 'last_boost_patient_seen';
export const LAST_RESERVED_TAB_BOOKING_SEEN_COOKIE_NAME = 'lrtbscn';
export const COVID_TESTING_DATA_COLLECTED_COOKIE_NAME = 'ctdccn';
export const COVID_TESTING_ANALYSIS_TIME_DATA_COLLECTED_COOKIE_NAME = 'ctatdccn';
export const PRODUCT_MARKET_FIT_NPS_SURVEY_MONKEY_DISMISSED_COOKIE_NAME = 'pmfnpssmdcn';
export const SOLV_NPS_SURVEY_DISMISSED_COOKIE_NAME = 'snsdcn';

export const googleApiKey = config.NEXT_PUBLIC_GOOGLE_GEO_CODE_API_KEY;
export const INTERCOM_ENABLED = mIntercomEnabled;
export const INTERCOM_APP_ID = getClientConfig('NEXT_PUBLIC_INTERCOM_APP_ID') || 'trcc5a83';

export const MAX_UPLOAD_SIZE_MB = 20;

export const MAX_ERRORS_BEFORE_RESET = 2;

export const TELEMED_PRE_CALL_FLOW_COOKIE = 'whatareyouteleme';

export const SLOT_LOOK_BACK_DURATION = 0; // milliseconds
export const QUEUE_PAST_BOOKING_THRESHOLD_IN_MINUTES = 15;
export const DEFAULT_SEARCH_RADIUS_IN_MILES = 100;
export const METERS_IN_A_MILE = 1609;

export const SOLV_CLINIC_GOOGLE_MAP_BASE_URL =
  'https://www.google.com/maps/d/u/0/edit?mid=16C8_IzQloPrX7L2NH1LotBExseo&z=14&ll=';

export const QUEUE_ACTIVE_TAB_RESERVED_WINDOW = 60;

export const isDev = () => SOLV_ENV === ENV_DEV;
export const isLocalHost = () =>
  typeof window !== 'undefined' &&
  (window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168'));
export const isStage = () => SOLV_ENV === ENV_STAGE;
export const isLiveStage = () => SOLV_ENV === ENV_LIVESTAGE;
export const isProd = () => SOLV_ENV === ENV_PROD || SOLV_ENV === ENV_SECURE_PROD;

let sentryDsn = null;
if (isProd()) {
  sentryDsn = 'https://82b1c71a417441dfb78798e1782f324a@sentry.io/107173';
}

export const SENTRY_DSN = sentryDsn;
export const SENTRY_ORGANIZATION = 'app51621729herokucom';
export const SENTRY_PROJECT = 'manage';
export const SENTRY_TOKEN = '816dc43ac4924f0780b202fac123585029c96b228c17465bb81103c4ae20610c';

export const HOST = mAppHost;
export const CONSUMER_APP_HOST = consumerAppHost;
export const MINIMUM_HIGHLY_RATED_FOR = 4.5;

export const CANNED_MESSAGES_BASE_URL = 'https://www.solvhealth.me';

export const ACUANT_BASE_URL =
  'https://cssnwebservices.com/CSSNService/CardProcessor/ProcessMedInsuranceCard';

const AGE_OF_OLDEST_PERSON_ALIVE = 118;
export const EARLIEST_YEAR_ALLOWED = thisYear() - AGE_OF_OLDEST_PERSON_ALIVE;

export const IDLE_TIMEOUT = 30 * 1000; // 30 seconds

export const QUEUE_DATA_REFRESH_INTERVAL = 1800 * 1000; // 30 minutes
export const LOCATION_DATA_REFRESH_INTERVAL = 300 * 1000 * 2; // 10 minute
export const CALL_CENTER_DATA_REFRESH_INTERVAL = 120 * 1000; // 2 minute
export const PULSE_DATA_REFRESH_INTERVAL = 60 * 1000; // 1 minute
export const WAITLIST_REFRESH_INTERVAL = 30 * 1000; // 30 seconds
export const TV_WAITLIST_WINDOW_REFRESH_INTERVAL = 60 * 60 * 1000; // 1 hour in milliseconds
export const CLOCK_TICK_INTERVAL = 60 * 1000; // 1 minute

export const KIOSK_REFRESH_INTERVAL = 10 * 60 * 1000; // 10 minutes in milliseconds

export const BOOKING_WIDGET_BASE_URL = 'https://www.solvhealth.com/book-online/';

if (isProd()) {
  stripeApiKey = 'pk_live_rHXxAGaubCMaOb3LSXdjNdhy';
}

export const STRIPE_API_KEY = stripeApiKey;
export const STRIPE_URL = 'https://js.stripe.com/v3/';
export const STRIPE_CLIENT_ID = stripeClientId;

export const MAX_CONTENT_WIDTH = 480;

export const BUSINESS_HOURS_INCREMENT_MINUTES = 15;

export const AUTO_LOGOUT_ROUTES = [
  '/login',
  '/queue',
  '/reports',
  '/settings',
  '/patients',
  '/payments',
  '/hello',
  '/call-center',
  '/pulse',
];

// https://web-push-codelab.glitch.me/
// or https://github.com/web-push-libs/web-push#command-line
// read more about it here https://developers.google.com/web/fundamentals/push-notifications/subscribing-a-user
export const NOTIFICATIONS_APPLICATION_SERVER_KEY =
  'BPwC7wRhtAsnrkmTsKNN8_3FpR_qFqlBxpcQgvuT8Pdkay7i9KqO1jB0nlgZlc4yAKgWVfcqfh7fUdd7eCD4HEg';

export const CLOUD_FRONT_URL = 'https://d3hmu1js3tz3r1.cloudfront.net';
