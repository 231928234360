export const colors = {
  greyest: '#212121',
  notQuiteBlack: '#243242',
  fadeToBlack: '#313131',
  lightBlack: '#333333',
  tar: '#404040',
  squid: '#414f60',
  greyWay: '#5b6b7d',
  mrGray: '#666',
  greyOcean: '#709EBF',
  wholeGrayn: '#84909E',
  greyBae: '#8c98a4',
  lanaDelGrey: '#979797',
  greyLock: '#9da6b1',
  graydient: '#a5aeb8',
  theGreyAlbum: '#b3b3b3',
  greyWorm: '#c4c4c4',
  greyish: '#d9d9d9',
  dorianGrey: '#dcdcdc',
  shadowGrey: '#e9e6e6',
  amazingGrayce: '#ececec',
  whiteGrey: '#ededed',
  fatherJohnMisty: '#f3f3f3',
  foggyGrey: '#f8f9fb',
  grayDim: '#f9f9f9',
  theWhiteAlbum: '#fff',
  greytWhiteShark: '#e3e9ee',
  greyHound: '#b4c2cc',
  greyMatter: '#f8f8f8',
  grayvy: '#b4c2cd',
  grayMatter: '#6a7685',
  grey200: '#E3E5E6',
  grey300: '#C6CACF',
  grey600: '#222A33',
  antGrey: '#e9e9e9',
  darkGray: '#4D4D4D',
  graysonChance: '#DEE4E7',
  miGrayne: '#909090',
  lightBlueGray: '#567B8F',
  limeade: '#50AF07',

  lightPink: '#ffe7f3',
  crunchberry: '#ff0e88', // NAMING: crunchBerry
  solvPink: '#ff62b2',
  cherryPie: '#f0006a',
  ladyInRed: '#ff234e',
  pinkish: '#ed6daf',
  redAlert: '#ce2424',
  antDanger: '#ff4d4f', // the 'danger' color used in the antd lib

  peaches: '#f5a623', // and cream
  lemonade: '#f5ff93',
  /* Closest yellow to antd's "warning" color that's still accessible against a white background. */
  a11yYellowAgainstWhite: '#946300',

  greenPine: '#3ad5ab',
  seaFoam: '#3ecbbb',
  grannySmith: '#45c071',
  coralGreen: '#17c2b9',
  greenMachine: '#19d1c7',

  blueLoadingScreen: '#516072',
  blueMonday: '#5b6b7d',
  blueish: '#809db2',
  karl: '#edf4f9',
  primaryBlue: '#108ee9',
  bahamasBlue: '#00669d',
  tangledUpInBlue: '#0084cc',
  whiteBlue: '#B5DFF6',
  blueJayWay: '#169ae1',
  blueMQV: '#179be1',
  mrBlueSky: '#40b6f5',
  gradientBlue: '#f1fbff',
  backgroundBlue: '#f5f9fb',
  greyBlue: '#A6C7DF',
  theHolyGrayl: '#709ebf',
  gotTheBlues: '#e5eaee',
  comeSailAway: '#10d1b2',
  blueDefaultCTAHover: '#2caff5',
  blueDefaultCTAActive: '#0487cc',
  reportsCTA: '#0071DB',

  bloop: '#f0a6ff',
  bruisePurple: '#693673',
  purpleRain: '#682e9a',
  purpleHaze: '#af5ebf',
  pomegranate: '#d76eac',
  purpleGradLight: '#bf5eaf',
  purpleGradDark: '#9f5ebf',
  purpleSky: '#9036d5',
  lightGreyBg: '#f3f4f5',
  lightGetbg2: '#fafafa',
  lightGrey3: '#f2f2f2',
  softBlue: '#5fb4ef',

  white: '#fff', // theWhiteAlbum
  black: '#313131', // theBlackAlbum
  link: '#0084cc', // tangledUpInBlue

  border: {
    blue: '#40a9ff',
  },
};

export const QUEUE_LOCATION_LABEL_COLORS = [
  '#FF7974',
  '#FE9C6E',
  '#AD4D02',
  '#95DE64',
  '#6BDA93',
  '#90D5FF',
  '#AF5EBF',
  '#FFAED1',
  '#F5222D',
  '#FAAD14',
  '#5C2400',
  '#A0D812',
  '#45C071',
  '#1990FF',
  '#8C399D',
  '#F75AAA',
  '#AD2201',
  '#F58A15',
  '#5B6A7E',
  '#5B8C01',
  '#14C2C3',
  '#0050B3',
  '#69299D',
  '#F0006A',
  '#A8081A',
  '#FA6400',
  '#979797',
  '#227805',
  '#046D74',
  '#3053EB',
  '#6236FF',
  '#B620E0',
  '#5C0011',
  '#AC6800',
  '#7F9CB3',
  '#253F00',
  '#012329',
  '#012765',
  '#482250',
  '#9E0E68',
];
