import moment from 'moment-timezone';
import { EMPTY_ARRAY, isEmptyArray } from './array';
import { titleCase } from './string';
import { ServiceIdentifiers } from '../../constants';

const STATUS_IN_PROGRESS = 'in-progress';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';
const STATUS_WARNING = 'warning';

const ACTION_SCHEDULE = 'schedule-appointment';
const ACTION_UPDATE_DEMOGRAPHICS = 'update-demographics';
const ACTION_ADD_PATIENT = 'add-patient';
const ACTION_UPLOAD_INSURANCE = 'upload-insurance-card';
const ACTION_UPLOAD_CONSENT = 'upload-consent-form';
const ACTION_CANCEL = 'cancel-appointment';
const ACTION_UPLOAD_PHOTO_ID = 'upload-photo-id';

const RESOLUTION_DISMISSED = 'dismissed';

const ACTION_DESCRIPTION_MAP = {
  [ACTION_ADD_PATIENT]: 'Patient',
  [ACTION_SCHEDULE]: 'Schedule',
  [ACTION_UPDATE_DEMOGRAPHICS]: 'Demographics',
  [ACTION_UPLOAD_INSURANCE]: 'Insurance',
  [ACTION_UPLOAD_CONSENT]: 'Consent',
  [ACTION_CANCEL]: 'Cancellation',
  [ACTION_UPLOAD_PHOTO_ID]: 'Photo ID',
};

const DEMOGRAPHICS_FIELDS = [
  'address',
  'birthSex',
  'customQuestions',
  'dateSigned',
  'email',
  'emergencyContact',
  'ethnicity',
  'phone',
  'race',
  'ssn',
];

const isIntegrationEnabled = (location, serviceId) => {
  if (isEmptyArray(location?.serviceLocations)) return false;

  const activeIntegrations = location.serviceLocations.filter(
    (serviceLocation) => serviceLocation.service_identifier === serviceId
  );

  return activeIntegrations.length !== 0;
};

const isIntegrationStatusEnabled = (location) => {
  if (
    isIntegrationEnabled(location, ServiceIdentifiers.MEDSTAR.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.DOCUTAP.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.ATHENA.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.ECW.id) ||
    isIntegrationEnabled(location, ServiceIdentifiers.PRACTICE_VELOCITY.id)
  ) {
    return true;
  }
  return false;
};

const isResolvingDuplicatesEnabled = (location) =>
  isIntegrationEnabled(location, ServiceIdentifiers.MEDSTAR.id);

const getStatusList = (booking) => {
  const { integration_status: statusList } = booking;

  if (isEmptyArray(statusList)) {
    return EMPTY_ARRAY;
  }

  return statusList;
};

const isTimedOut = (status) => moment() > moment(status.integration_start).add(11, 'minutes');

const isInProgress = (status) => status.status === STATUS_IN_PROGRESS && !isTimedOut(status);

const isWarning = (status) => status.status === STATUS_WARNING;

const isError = (status) =>
  status.status === STATUS_ERROR || (status.status === STATUS_IN_PROGRESS && isTimedOut(status));

const isErrorDismissed = (status) => status.resolution === RESOLUTION_DISMISSED;

const isSuccess = (status) => status.status === STATUS_SUCCESS;

const getResponseJson = (status) => {
  const response = status.requests[0]?.response;
  if (!response) {
    return {};
  }

  try {
    return JSON.parse(response);
  } catch (e) {
    return {};
  }
};

const getPatientIdFromResponse = (status) => {
  const response = getResponseJson(status);
  if (response?.patientId) {
    // Only commented out because legal hasn't approved
    // displaying EMR #. Once approved, we can uncomment
    // the lines of code below to show the EMR number
    // return ` EMR #${response.patientId}`;
    return '';
  }
  return '';
};

const getDemographicsFields = (status) => {
  const fields = status.requests[0]?.fields;
  try {
    if (!isEmptyArray(fields)) {
      return fields
        .filter((field) => DEMOGRAPHICS_FIELDS.includes(field))
        .map((field) =>
          titleCase(
            field.replace(
              /([A-Z])/g,
              (originalString, firstCharacter) => ` ${firstCharacter.toLowerCase()}`
            )
          )
        );
    }
  } catch (e) {
    return [];
  }

  return [];
};

const EMR_TRANSFER_CANCELED_DUPLICATES = 'EMR transfer canceled: Duplicate found';

const getIntegrationStatusSummary = (statuses) => {
  const lastStatus = statuses[statuses.length - 1];
  const actionDescription = ACTION_DESCRIPTION_MAP[lastStatus.action];
  if (isWarning(lastStatus) && isErrorDismissed(lastStatus)) {
    return `${EMR_TRANSFER_CANCELED_DUPLICATES} dismissed`;
  } else if (isWarning(lastStatus)) {
    return `${EMR_TRANSFER_CANCELED_DUPLICATES}`;
  } else if (isErrorDismissed(lastStatus)) {
    return 'EMR transfer error dismissed';
  } else if (isError(lastStatus)) {
    return actionDescription
      ? `${actionDescription} failed to transfer to EMR`
      : 'Failed to transfer to EMR';
  } else if (isInProgress(lastStatus)) {
    return actionDescription
      ? `${actionDescription} transfer in progress to EMR`
      : 'In progress to EMR';
  } else if (isSuccess(lastStatus)) {
    return 'EMR transfer successful';
  }

  return null;
};

const getMessageForStatus = (status) => {
  switch (status.action) {
    case ACTION_SCHEDULE:
      if (isSuccess(status)) {
        return `Booking successfully scheduled${getPatientIdFromResponse(status)}`;
      } else if (isInProgress(status)) {
        return 'Please wait 60-90 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please check and enter the appropriate patient ID';
      } else if (isError(status)) {
        return 'Error scheduling booking';
      }
      break;
    case ACTION_UPDATE_DEMOGRAPHICS:
      if (isSuccess(status)) {
        return `Demographics successfully updated: ${getDemographicsFields(status).join(', ')}`;
      } else if (isInProgress(status)) {
        return 'Demographics update in progress. Please wait 30-60 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please check and add patient manually';
      } else if (isError(status)) {
        return 'Error updating demographics';
      }
      break;
    case ACTION_ADD_PATIENT:
      if (isSuccess(status)) {
        return `Patient successfully added${getPatientIdFromResponse(status)}`;
      } else if (isInProgress(status)) {
        return 'Please wait 60-90 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please check and add patient manually';
      } else if (isError(status)) {
        return 'Potential error adding patient';
      }
      break;
    case ACTION_UPLOAD_INSURANCE:
      if (isSuccess(status)) {
        return `Insurance successfully uploaded`;
      } else if (isInProgress(status)) {
        return 'Please wait 60-90 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please upload insurance manually';
      } else if (isError(status)) {
        return 'Potential error uploading insurance';
      }
      break;
    case ACTION_UPLOAD_CONSENT:
      if (isSuccess(status)) {
        return `Consent successfully uploaded`;
      } else if (isInProgress(status)) {
        return 'Please wait 60-90 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please upload consent manually';
      } else if (isError(status)) {
        return 'Potential error uploading consent';
      }
      break;
    case ACTION_CANCEL:
      if (isSuccess(status)) {
        return `Appointment successfully canceled`;
      } else if (isInProgress(status)) {
        return 'Please wait 60-90 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please cancel appointment manually';
      } else if (isError(status)) {
        return 'Potential error canceling appointment';
      }
      break;
    case ACTION_UPLOAD_PHOTO_ID:
      if (isSuccess(status)) {
        return `Photo ID successfully uploaded`;
      } else if (isInProgress(status)) {
        return 'Please wait 60-90 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please upload Photo ID manually';
      } else if (isError(status)) {
        return 'Potential error uploading Photo ID';
      }
      break;
    default:
      if (isSuccess(status)) {
        return `Transfer successful`;
      } else if (isInProgress(status)) {
        return 'Please wait 60-90 seconds for the information to transfer';
      } else if (isWarning(status)) {
        return 'Please transfer manually';
      } else if (isError(status)) {
        return 'Potential error';
      }
      break;
  }
  return null;
};

export {
  getStatusList,
  isIntegrationStatusEnabled,
  isIntegrationEnabled,
  isInProgress,
  isError,
  isErrorDismissed,
  isResolvingDuplicatesEnabled,
  isSuccess,
  isTimedOut,
  isWarning,
  getMessageForStatus,
  getIntegrationStatusSummary,
};
