import { DOWNLOAD_REPORT_TAB_PATH, PATIENT_BOOST_REPORT_TAB_PATH } from '../../constants';
import { isFrontOfficeUser, isBackOfficeUser, isProviderGroupUser } from '../../core/session';
import { Location, LocationUnformatted, AccountLogin } from '../../types/RootState';
import moment from 'moment-timezone';

export const getReportsDefaultTabPath = (login: AccountLogin) => {
  if (isFrontOfficeUser(login) || isBackOfficeUser(login) || isProviderGroupUser(login)) {
    return DOWNLOAD_REPORT_TAB_PATH;
  }

  return PATIENT_BOOST_REPORT_TAB_PATH;
};

/**
 * Given a time frame of number of days ago, return an object with start and end moment objects representing the range
 * @param {string} fromNumberOfDaysAgo
 * @param {string} toNumberOfDaysAgo
 * @return {object} { start: moment object, end: moment object }
 */

export const getDateRange = (fromNumberOfDaysAgo: string, toNumberOfDaysAgo: string) => {
  const start = moment.tz(moment.tz.guess()).subtract(fromNumberOfDaysAgo, 'days').startOf('day');
  const end = moment.tz(moment.tz.guess()).subtract(toNumberOfDaysAgo, 'days').endOf('day');
  return { start: start, end: end };
};

export const filterLocationsByLocationIds = (
  locations: Record<string, Location & LocationUnformatted>,
  locationIds: string[]
): Record<string, Location & LocationUnformatted> =>
  Object.keys(locations)
    .filter((key) => locationIds.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: locations[key] });
    }, {});

const isLiveDateMoreThanAMonthAgo = (liveDate: Date): boolean =>
  moment(liveDate).add(28, 'days').isBefore(moment());

export const getPerformancePricingLocationsInfo = (
  locations: Record<string, Location & LocationUnformatted>,
  locationIds: string[]
) => {
  const locationsData = filterLocationsByLocationIds(locations ?? [], locationIds);
  const performancePricingLocationIds = Object.keys(locationsData).filter(
    (key) => locationsData[key].isPerformancePricingEnabled && locationsData[key].liveDate
  );

  const performancePricingLocations = filterLocationsByLocationIds(
    locations ?? [],
    performancePricingLocationIds
  );
  const hasPerformancePricingLocations = performancePricingLocationIds.length > 0;
  const locationIdsLiveMoreThan30Days = performancePricingLocationIds.filter((key) =>
    isLiveDateMoreThanAMonthAgo(locationsData[key].liveDate)
  );
  /*
   * performancePricingLocations: data for locations that have performance pricing enabled
   * locationsLiveMoreThan30Days: ids for locations that have a live date more than 30 days ago
   * hasPerformancePricingLocations: whether or not any of the selected locations have performance pricing enabled
   *  */
  return {
    performancePricingLocations: performancePricingLocations,
    hasPerformancePricingLocations: hasPerformancePricingLocations,
    locationIdsLiveMoreThan30Days: locationIdsLiveMoreThan30Days,
  };
};

export const getEarliestLiveDate = (
  locations: Record<string, Location & LocationUnformatted>
): moment.Moment => {
  let liveDates = Object.keys(locations)
    .map((k) => locations[k]?.liveDate)
    .filter((liveDate) => liveDate);
  let moments = liveDates.map((d) => moment(d));
  let minDate = moment.min(moments);
  return minDate;
};
