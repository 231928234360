import { DAPI_HOST } from '../../config';

const getExpressConnectUrl = locationId =>
  `${DAPI_HOST}/v1/payment-accounts/stripe-account/${locationId}`;

const getPaymentAccountsUrl = paymentAccountId =>
  `${DAPI_HOST}/v1/payment-accounts/${paymentAccountId}`;

const getCreatePaymentAccountUrl = () => `${DAPI_HOST}/v1/payment-accounts`;

export { getExpressConnectUrl, getPaymentAccountsUrl, getCreatePaymentAccountUrl };
